import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AvatarWithPresenceState } from "../ui/AvatarWithPresenceState";
import moment, { Moment } from "moment";
import { useLanguageState } from "../globalStates/LanguageState";
import { IconMuteChat } from "../ui/Icons";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { ConversationType } from "../API";
import { getLastReadUserConversation } from "../backendServices/BackendServices";
import branding from "../branding/branding";

export interface ConversationEntryBranding {
    conversationEntryTextColor: string
    unreadMarkerBgColor: string
}

function isToday(dateMoment: Moment): boolean {
    const today = moment().clone().startOf("day")
    return dateMoment.isSame(today, "d")
}

function isYesterday(dateMoment: Moment): boolean {
    const yesterday = moment().clone().subtract(1, "days").startOf("day")
    return dateMoment.isSame(yesterday, "d")
}

export function calcLastMessageTime(time?: Date): string {
    if (!time) {
        return ""
    }
    const timeMoment = moment(time)
    if (isToday(timeMoment)) {
        return timeMoment.format("HH:mm")
    }
    if (isYesterday(timeMoment)) {
        return timeMoment.calendar().split(' ')[0]
    }
    if (moment().diff(timeMoment, "days") < 7) { // show relative dates for a week

        const currentLanguage = localStorage.getItem("virtualGuide-language")
        

        if (currentLanguage === "de") {
            timeMoment.locale("de")
        } else {
            timeMoment.locale("en")
        }

        return timeMoment.format("dddd")
    }
    return timeMoment.format("L")
}

type ConversationProps = {
    title: string
    conversationType: ConversationType
    opponentIds: string[]
    logoUrls: (string | undefined)[]
    altNames: string[]
    lastMessage?: string
    lastMessageTime?: Date
    isMuted: boolean
    userConversationId: string
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
};

const ConversationEntryRoot = styled.div`
    cursor: pointer;
    background-color: #fff;
  
    &:hover {
      background-color: rgba(240, 240, 240, 1);
    }
    text-decoration: none !important;
`;

const ConversationEntryContainer = styled.div`
    display: flex;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;
    text-decoration: none !important;
`;

const BottomBorder = styled.div`
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"};
    margin-left: 20px;
`;

const ConversationData = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    overflow: hidden;
    font-family: ${branding.font1};
    color: ${branding.conversationEntry.conversationEntryTextColor ?? "#000"};
  `;

const ConversationTitle = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    /* color: rgb(10, 10, 10); */
    font-family: ${branding.font1};
    color: ${branding.conversationEntry.conversationEntryTextColor ?? "rgb(10, 10, 10)"};
  `;

const LastMessage = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 6px;
    font-size: 12px;
    /* color: rgb(10, 10, 10); */
    font-family: ${branding.font1};
    color: ${branding.conversationEntry.conversationEntryTextColor ?? "rgb(10, 10, 10)"};
`;

const MuteIcon = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    
    & svg {
      flex: 1 0 auto;
      width: 20px;
      height: 20px;
    }
  `;

const StatusPanel = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;

const LastMessageTime = styled.div`
    padding-top: 7px;
    padding-left: 2px;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    //color: rgb(10, 10, 10);
    font-family: ${branding.font1};
    color: ${branding.conversationEntry.conversationEntryTextColor ?? "rgb(10, 10, 10)"};
`;

const UnreadMarker = styled.div`
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: ${branding.conversationEntry.unreadMarkerBgColor ?? "#0000FF"} ;
    margin-top: 22px;
    padding: 5px;
`

const ConversationEntry: React.FunctionComponent<ConversationProps> = (props) => {

    const loggedInState = useLoggedInState()
    const languageState = useLanguageState()

    const profile = loggedInState.user()!
    const [newMessage, setNewMessage] = useState(0)

    moment.locale(languageState.getLanguage())

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick(event)
        }
        event.stopPropagation()
        event.preventDefault()
    }

    let userIds: string[]
    let logoUrls: (string | undefined)[]
    let altNames: string[]
    if (props.conversationType === ConversationType.GROUP || props.conversationType === ConversationType.CALENDARENTRY) {
        userIds = [...props.opponentIds, profile.profileId]
        logoUrls = [...props.logoUrls, profile.logoUrl]
        altNames = [...props.altNames, [profile.firstName, profile.middleName, profile.lastName].filter(Boolean).join(" ")]
    } else {
        userIds = props.opponentIds
        logoUrls = props.logoUrls
        altNames = props.altNames
    }

    useEffect(() => {
        (async () => {
            const userConversation = await getLastReadUserConversation(props.userConversationId)
            if (!userConversation?.lastReadMessageCreatedAt || userConversation.lastReadMessageCreatedAt < userConversation.mostRecentMessageCreatedAt!)
                setNewMessage(1)
            else
                setNewMessage(0)
        })()
    }, [props.userConversationId, newMessage, props.lastMessage])

    return (
        <ConversationEntryRoot onClick={handleClick}>
            <ConversationEntryContainer>
                {newMessage === 1 && <UnreadMarker />}
                <LogoContainer userIds={userIds} logoUrls={logoUrls} altNames={altNames} profileId={loggedInState.user()?.profileId} />
                <ConversationData>
                    <ConversationTitle title={props.title}>{props.title}</ConversationTitle>
                    {props.lastMessage && <LastMessage>{props.lastMessage}</LastMessage>}
                </ConversationData>
                {props.isMuted && <MuteIcon>{IconMuteChat({ fill: branding.sideIconBar.sideIconColorDark })}</MuteIcon>}
                <StatusPanel>
                    <LastMessageTime>{calcLastMessageTime(props.lastMessageTime)}</LastMessageTime>
                </StatusPanel>
            </ConversationEntryContainer>
            <BottomBorder />
        </ConversationEntryRoot>
    );
};


const LogoContainerRoot = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    width: 56px;
    height: 56px;
    flex-direction: row;
    flex-wrap: wrap;


    .avt-cont {
        flex: 1;
        flex-grow: 0;
    }
`

const OverflowCount = styled.div`
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border:1px solid black;
    border-radius: 50%;
`

interface LogoContainerProps {
    userIds: string[]
    logoUrls: (string | undefined)[]
    altNames: string[]
    profileId: string | undefined
}

const LogoContainer: React.FC<LogoContainerProps> = (props) => {
    if (props.userIds.length === 1) {
        return <AvatarWithPresenceState userId={props.userIds[0]} initPresenceByList={false} avatarSize={56} badgeSize={15} showAvatarBadge={true} badgeRight={0.1} badgeTop={37} content={{ pictureUrl: props.logoUrls[0], alt: props.altNames[0] }} />
    }
    const maxLogos = props.logoUrls.length === 4 ? 4 : 3

    return (
        <LogoContainerRoot>
            {props.userIds.slice(0, maxLogos).map((userId, i) => {
                return <AvatarWithPresenceState key={userId} userId={userId} avatarSize={27} badgeSize={12} showAvatarBadge={true} badgeRight={0} badgeTop={17} badgeLeft={12} content={{ pictureUrl: props.logoUrls[i], alt: props.altNames[i] }} />
            })}
            {props.logoUrls.length > maxLogos && <OverflowCount>{props.logoUrls.length - maxLogos}</OverflowCount>}
        </LogoContainerRoot>
    )
}

export { ConversationEntry }
