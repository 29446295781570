import React, { useState, } from "react"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import styled from "styled-components"
import branding, { LocalizedString } from "../branding/branding"
import { InvitePerson, ShareTargetType } from "../backendServices/Types"
import { IconClose } from "./Icons"
import AvatarWithPresenceState from "./AvatarWithPresenceState"
import { shareTargetMailNotification, sendChatMessageAndCreateConversationIfNecessary, loadExhibitorData, trackVisit } from "../backendServices/BackendServices"
import { OverlayTrigger, Tooltip, Modal, Button, Form } from "react-bootstrap"
import SearchPersonInput from "../communicationArea/SearchPersonInput"
import ReactDOM from "react-dom"

const Title = styled.div`
    font-size: 25px;
    line-height: 20px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
`
const BelowTitle = styled.div`
    margin-top: 30px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    font-style: normal;
    color: ${branding.mainInfoColor};
`

const SubmitButtonContainer = styled.div`
    /* width: 45%;
    margin-left: 30%;
    margin-top: 20px;
    margin-bottom: 25px; */
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
`

const StyledSearchPersonInput = styled(SearchPersonInput)`
    /* margin: 4px;
    padding-right: 4px;
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor};
    line-height: 40px;
    font-family: ${branding.font1};
    background-color: #e8e8e8; */
    border-bottom: 1px solid ${branding.mainInfoColor};
    line-height: 36px;
    height: 36px;
    margin-bottom: 24px;
    background-color: #e8e8e8;
    font-family: ${branding.font1};
`

const InvitePersonRoot = styled.div`
    position: relative;
    margin-left: 10px;

    :hover #badgeArea{
        visibility: visible;
    }

    .rs-tooltip {
        display: none;
        position: absolute;
        bottom: 0;
    }

    :hover .rs-tooltip {
        display: block;
    }
`

const PeopleDisplayArea = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    ${InvitePersonRoot}:first-child {
        margin-left: 0;
    }

    .rs-avatar-lg {
        margin-left: 7px;
    }
    .rs-avatar-image {
        object-fit: cover;
    }
`

const SubmitButton = styled(Button)`
    display: block;
    width: 30%;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important; 
    }
`
const CancelButton = styled(Button)`
    display: block;
    width: 30%;
    /* margin-top: 20px; */
    color: ${branding.primaryColor ?? "#000"};
    background-color: transparent;
    border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    font-size: 12px;
    font-family: ${branding.font1};
    margin-right: 15px;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important; 
    }
`
const NotesRoot = styled.div`
    #recommend-textarea {
        font-family: ${branding.font1};
        font-size: 12px;
        border: 2px solid ${branding.mainInfoColor};
        border-radius: 0px;
        resize: auto;
        cursor: default;
    }
`
const InputRoot = styled.div`
    font-size: 1rem;
    font-weight: bold;
    font-family: ${branding.font1};
    svg, .form-label {
        color: ${branding.secondaryColor}
    }
`

const BadgeContentArea = styled.div`
    position: absolute;
    visibility: hidden;
    top: 0;
    right: 0;

    & .badge { 
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0; 
        width: 17px;
        height: 17px;
        background-color: ${branding.dangerButtonColor};
        color: white;
        cursor: pointer;
        font-size: 12px;
        border-radius: 50%;
    }
`

const RecommendOrganizationMainModal = styled(Modal)`
    width: 600px;
    height: auto;
    overflow: hidden !important;
    left: 50%;
    transform: translateX(-300px);

    .modal-dialog{
        max-width: 600px;
    }

    .modal-body{
        overflow: visible;
        padding: 0;
    }

    .modal-content {
        padding: 0 50px;
        border-radius: 0px;
    }

    .modal-header {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
    }

    .modal-title{
        display: flex;
        align-items: center;
    }

    .modal-header .modal-title {
        color: ${branding.mainInfoColor};
        font-weight: 300;
        overflow: visible;
        white-space: normal;
    }

    .modal-footer{
        justify-content: space-between;
        border: none;
        padding: .15rem;
    }

    .control-label {
        color: ${branding.mainInfoColor};
    }

    .rs-form-vertical .rs-form-group .rs-input-group{
        width: 100%;
    }

    .rs-btn{
        font-size: 12px;
    }

    .close{
        outline: 0;
        font-size: 25px;
        font-weight: lighter;
        color: ${branding.mainInfoColor};
        width: 25px;
        background: none;
        border-style: none;
        opacity: 1;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }

    .close:not(:disabled):not(.disabled):focus,
    .close:not(:disabled):not(.disabled):hover{
        opacity: 1;
    }

    .customString{
        font-weight: bold;
    }

    svg {
        color: ${branding.primaryColor}
    }
`
// const DisagreeButton = styled.div`
//     width: 50%;
//     height: 30px;
//     cursor: pointer;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     color: ${branding.primaryColor};

//     &:hover{
//         text-decoration: none;
//     }
// `
const CloseButton = styled.div`
    /* margin-top: 8px; */
    /* margin-bottom: auto;
    margin-left: auto; */
    /* margin-right: 0px; */
    /* padding-right: 1rem; */
    padding-top: 2px;
    cursor: pointer;
    svg {
       color: ${branding.mainInfoColor};
       }
`
export interface RecommendModalBranding {
    title: LocalizedString
    titleContact: LocalizedString
    subtitlePart1: LocalizedString
    subtitlePart2: LocalizedString
    shareButton: LocalizedString
    sendMessageOrganization: LocalizedString
    sendMessagePerson: LocalizedString
    sendMessageSotUser: LocalizedString
    sendMessageEventdate: LocalizedString
    notes: LocalizedString
    notesPlaceholder: LocalizedString
    submitBtnPrimaryOnHoverBgColor: string
    submitBtnPrimaryOnHoverTextColor: string
    submitBtnPrimaryBgColor: string
    submitBtnPrimaryTextColor: string
    submitBtnPrimaryBorderColor: string
    textareaCounterTextColor: string
}


interface RecommendOrganizationModalProps {
    sotUser?: InvitePerson[]
    targetId: string
    link: string
    type: ShareTargetType
    close: () => void
    open?: () => void
}

const RecommendOrganizationModal = (props: RecommendOrganizationModalProps) => {
    const userlink = useLoggedInState()
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    const [contactsInvited, setContactsInvited] = useState<InvitePerson[]>(props.sotUser ? props.sotUser : [])
    const [notes, setNotes] = useState("")
    const profileId = userlink.user()?.profileId
    const MAX_NOTES_LENGTH = 150

    const [name, setName] = useState<string>("");

    if (props.type === ShareTargetType.ORGANIZATION) {
        loadExhibitorData({
            organizationid: props.targetId,
            hideNewsdata: false
        }).then((resp) => {
            setName(resp.content?.name);
        }).catch((e: { message: React.SetStateAction<string> }) => {
            // Error logged in Backend Services
        })
    }

    function onShareExhibitor() {
        onCloseModalView()
        contactsInvited.forEach(contact => {
            shareTargetMailNotification({
                targetId: contact.id,
                organizationId: props.targetId,
                type: props.type,
                notes: notes
            })

            var message: string
            switch (props.type) {
                case ShareTargetType.ORGANIZATION:
                    trackVisit(userlink.user()!.profileId, props.targetId, 'RECOMMENDATION#SENT')
                    if (notes !== "")
                        message = strings.recommendModal.sendMessageOrganization + props.link + `\n\n${strings.recommendModal.notes}: ` + notes
                    else
                        message = strings.recommendModal.sendMessageOrganization + props.link
                    break
                case ShareTargetType.PERSON:
                    if (notes !== "")
                        message = strings.recommendModal.sendMessagePerson + props.link + `\n\n${strings.recommendModal.notes}: ` + notes
                    else
                        message = strings.recommendModal.sendMessagePerson + props.link
                    break
                case ShareTargetType.SOTUSER:
                    if (notes !== "")
                        message = strings.recommendModal.sendMessageSotUser + props.link + `\n\n${strings.recommendModal.notes}: ` + notes
                    else
                        message = strings.recommendModal.sendMessageSotUser + props.link
                    break
                case ShareTargetType.EVENTDATE:
                    let eventdate = strings.recommendModal.sendMessageEventdate.split('{$receiverName}').join(contact.firstName)
                    eventdate = eventdate.split('{$senderName}').join(userlink.user()?.firstName + " " + userlink.user()?.lastName)
                    if (notes !== "")
                        message = eventdate + props.link + `\n\n${strings.recommendModal.notes}: ` + notes
                    else
                        message = eventdate + props.link
                    break

            }
            sendChatMessageAndCreateConversationIfNecessary(userlink.user()!.profileId, contact.id, message)
        })
    }

    function onCloseModalView() {
        if (profileId === undefined) {
            return;
        }
        setContactsInvited(props.sotUser ? props.sotUser : [])

        props.close()
    }

    function removePerson(item: any, event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        setContactsInvited(contactsInvited.filter(it => it.id !== item.id))
    }


    function onParticipantInvited(item: any) {
        setContactsInvited(contactsInvited.concat(item))
    }

    function onNotesChanged(value: string) {
        if (value.length > MAX_NOTES_LENGTH) {
            return
        }
        setNotes(value)
    }

    return (
        <RecommendOrganizationMainModal backdrop="static" show={true} animation={false} onHide={() => onCloseModalView()} onShow={() => props.open}>
            <div onClick={(e) => e.stopPropagation()}>
                <Modal.Header>
                    <div className="d-flex flex-column mt-2">
                        <Modal.Title><Title>{name !== "" ? strings.recommendModal.title : strings.recommendModal.titleContact}</Title></Modal.Title>
                        {name && <BelowTitle>{strings.recommendModal.subtitlePart1}<span className="customString">{name}</span>{strings.recommendModal.subtitlePart2}</BelowTitle>}
                    </div>
                    <CloseButton onClick={() => onCloseModalView()}>{IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}</CloseButton>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <InputRoot><Form.Label>{strings.calendarEntryModalPageContent.searchPerson + `(${contactsInvited.length})`}</Form.Label></InputRoot>
                        <PeopleDisplayArea>
                            {contactsInvited.map((contact, index) => {
                                return (
                                    <InvitePersonRoot key={contact.id}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                                                    {contact.lastName !== undefined ? contact.firstName + " " + contact.lastName : contact.firstName}
                                                </Tooltip>}>

                                            <AvatarWithPresenceState
                                                avatarSize={56}
                                                badgeSize={15}
                                                showAvatarBadge={true}
                                                badgeRight={0.1}
                                                badgeTop={37}
                                                userId={contact.id}
                                                content={
                                                    { pictureUrl: contact.logoUrl, alt: [contact.firstName, contact.lastName].filter(Boolean).join(" ") }}>
                                            </AvatarWithPresenceState>
                                        </OverlayTrigger>
                                        <BadgeContentArea id="badgeArea" onClick={(e) => removePerson(contact, e)}>
                                            <span className={'badge'}>x</span>
                                        </BadgeContentArea>
                                    </InvitePersonRoot>);
                            })}
                        </PeopleDisplayArea>
                        <Form.Group>
                            <StyledSearchPersonInput placeholder={strings.calendarEntryModalPageContent.searchPersonPlaceholder} personClicked={onParticipantInvited} excludeIds={[useLoggedInState().user()?.profileId || ""]} />
                        </Form.Group>
                        <Form.Group controlId="recommend-textarea" style={{ marginTop: "20px" }}>
                            <InputRoot><Form.Label>{strings.recommendModal.notes + " (" + notes.length + "/" + MAX_NOTES_LENGTH + ")"}</Form.Label></InputRoot>
                            <NotesRoot>
                                <Form.Control as="textarea" value={notes} onChange={(event) => onNotesChanged(event.target.value)} rows={4} placeholder={strings.recommendModal.notesPlaceholder} />
                                {/* <TextLimit textLength={notes?.length || 0} maxTextLength={MAX_NOTES_LENGTH} /> */}
                            </NotesRoot>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <SubmitButtonContainer>
                            <SubmitButton disabled={contactsInvited.length === 0} variant="primary" onClick={onShareExhibitor}>
                                {strings.recommendModal.shareButton}
                            </SubmitButton>
                        </SubmitButtonContainer> */}
                        <SubmitButtonContainer>
                            {/* <DisagreeButton onClick={() => props.close()} className="d-flex align-items-center">{strings.globalTexts.cancel}</DisagreeButton> */}
                            <CancelButton onClick={() => props.close()} className="d-flex align-items-center justify-content-center">{strings.globalTexts.cancel}</CancelButton>
                            <SubmitButton disabled={contactsInvited.length === 0} variant="primary" onClick={onShareExhibitor} className="d-flex align-items-center justify-content-center">{strings.recommendModal.shareButton}</SubmitButton>
                        </SubmitButtonContainer>
                    </Modal.Footer>
                </Form>
            </div>
        </RecommendOrganizationMainModal>
    )
}

export default RecommendOrganizationModal;


export const useRecommendOrganizationModal = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<{ shareType: ShareTargetType, entityId: string, link: string }>({ shareType: ShareTargetType.EVENTDATE, entityId: "", link: "" })

    const showRecommendOrganizationModal = (shareType: ShareTargetType, entityId: string, link: string) => {
        setData({ shareType: shareType, entityId: entityId, link: link })
        setIsVisible(true)
    }

    const OrganizationModal = () => (
        ReactDOM.createPortal(<React.Fragment>
            {isVisible && <RecommendOrganizationModal
                targetId={data.entityId}
                type={data.shareType}
                link={`https://${window.location.hostname}` + data.link}
                close={() => setIsVisible(false)} />}
        </React.Fragment>, document.body)
    )

    return {
        showRecommendOrganizationModal,
        OrganizationModal,
    }
}


