import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { TabContentArea, TabRoot } from "./CommunicationArea"
import ToggleSwitch from "../ui/ToggleSwitch"
import branding from "../branding/branding"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { useLanguageState } from "../globalStates/LanguageState"
import { useHistory } from "react-router-dom"
import { AvatarWithPresenceState } from "../ui/AvatarWithPresenceState"
import DeviceSwitcher from '../conference/components/DeviceSwitcher'
import {
    BackendServiceError,
    TokenResponse,
    PresenceType,
    updateUserValues,
    logout,
    setUserSetting,
    Settings,
    SettingsKey
} from "../backendServices/BackendServices"
import { IconEdit, IconBlockContact, IconChevronRight, IconChevronLeft, IconCamera, IconShow, IconHide, IconTimeZone, IconArrowHeadDown, IconArrowHeadUp } from "../ui/Icons"
import { BlockedTab } from "./NetworkingTab"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import { useAppState } from "../globalStates/AppState"
import { useChimeContext } from "../conference/context/ChimeContext"
import { useFavoriteState } from "../globalStates/Favorites"
import { endSession } from "../tracking/RouteTracker"
import { Divider } from "./ContactEntry"
import moment from 'moment-timezone';
import { findTimezoneName, getTimezones, Timezone } from "../DateUtils"
import NetworkingToggle from "./components/NetworkingToggle"
import { myProfilePageRoute } from "../navigationArea/RoutePaths"
import { DoNotDisturbToggle } from "./components/DoNotDisturbToggle"

const version = document.getElementById("version")?.getAttribute("data-value")

export interface SettingsTabBranding {
    settingsAreaRowColor: string
    timezonePickerRowMarginTop: string
}


const HeaderArea = styled.div`
  display: grid;
  grid-template-columns: 20% 65% 15%;
  flex-direction: row;
  margin-left: 15px;
  background-color: #fff;
  width: inherit;
  overflow: hidden;
  padding-bottom:5px;
  color: ${branding.mainInfoColor ?? "black"};
`;

const SettingsArea = styled.div`
  .settingsOpen & {
    visibility: visible;
  }
  svg {
    color: ${branding.primaryColor ?? "black"};
  }
`;

export const SettingsAreaRow = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  display: grid;
  height: 40px;
  margin-left: 15px; 
  grid-template-columns: 15% 65% 20%; 	
  padding-bottom:10px !important;
  padding-top:0px;
  margin-top: 5px;	
  margin-bottom: 5px;
  background-color: #fff;
  width: inherit;
  color: ${branding.settingsTab.settingsAreaRowColor ?? "#000"};
`;

const SettingsTitleRow = styled.div`
margin-top: 20px;
margin-bottom: 15px;
margin-left: 20px;
font-family: ${branding.font1};
font-style: normal;
font-weight: bold;
font-size: 15px;
display: inline-block;
max-width: 500px;
`;

const CurrentUserOrganization = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    display: inline-block;
    width: 250px;
    margin-left: 10px;
`;

const OrganizationRoot = styled.div<{ visibility: string }>`
    visibility: ${props => props.visibility};
    display: flex;
    flex-flow: column;
    margin-left: 20px; 
    padding-bottom:10px !important;
    padding-top:0px;
    margin-top: 5px;	
    margin-bottom: 5px;
    cursor: pointer;
`

const OrganizationLogoDiv = styled.div`
    width: 50px;
    height: 50px;

    img{
        width: 100%; 
        height: 100%;
        object-fit: contain;
    }
`

const CurrentUserName = styled.div`
  margin-top: 5px;
  margin-left: 2px;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  .networkingCollapsed & {
    visibility: hidden;
  }
`;

const CurrentUserPosition = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-size: 12px;
  margin-left: 2px;
  .networkingCollapsed & {
    visibility: hidden;
  }
`;

const BottomMenuCompanyLogo = styled.div`
    position: absolute;
    bottom: 100px;
    font-family: ${branding.font1};
    font-weight: 300;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    background: #fff;
    color: ${branding.mainInfoColor ?? "black"};
`;

const SubTabRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 320px);
  overflow-x: hidden;
  color: ${branding.mainInfoColor ?? "black"};
`;

const LogOutButtonRoot = styled.div`
  width: 320px;
  color: ${branding.dangerButtonColor};
  padding-bottom: 15px;
  position: relative;
  display: flex;
  flex-flow: row;
  background-color: white;
  justify-content: center;
`;

const LogOutButton = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 25px;
  color: ${branding.dangerButtonColor ?? "red"};
  transition: background-color 0.2s ease-in-out; 
  background-color: white;
  border: 1px solid ${branding.dangerButtonColor ?? "red"};
  border-radius: 20px;
  height:30px;
  width: 260px;
  padding: 1px;
  `;


const CustomDivider = styled(Divider)`

 .networkingCollapsed & {
    visibility: hidden;
  }
`

interface TimezonePickerProps {
    timezoneToggle: boolean
    timezonePicker: boolean
    setTimezonePicker: (timezonePicker: boolean) => void
}

const TimezonePickerRow = styled.div<{ selected?: boolean }>`
  display: flex;
  margin-top: ${branding.settingsTab.timezonePickerRowMarginTop};	
  margin-bottom: 5px;
  margin-left: 15px;
  color: ${branding.deviceSwitcher.deviceSwitcherRowColor ?? "#000"};
  p {
      flex: 1;
      color: ${props => props.selected ? "green " : ""};
      text-overflow: ellipsis;
      overflow: hidden;
  }

  svg {
      width: 20px;
      cursor: pointer;
  }
`;

const TimezoneSelectRoot = styled.div`
    height: 300px;
    overflow: auto;
`

const TimezonePicker: React.FC<TimezonePickerProps> = (props) => {

    const timezones = getTimezones()
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone)
    const strings = useLanguageState().getStrings()

    function onTimezoneSelect(item: Timezone) {
        setSelectedTimezone(item.timezones[0])
        appState.setTimeZone(item.timezones[0])
    }

    useEffect(() => {
        if (props.timezoneToggle)
            setSelectedTimezone(moment.tz.guess())

    }, [props.timezoneToggle])


    return (
        <>
            <TimezonePickerRow style={{ opacity: props.timezoneToggle ? "0.25" : "", pointerEvents: props.timezoneToggle ? "none" : "auto", borderBottom: props.timezonePicker ? "1px solid" : "" }}>
                <p style={{ marginBottom: "15px" }}>{selectedTimezone ? findTimezoneName(selectedTimezone) : strings.communicationArea.timezonePickerText}</p>
                <div onClick={() => props.setTimezonePicker(!props.timezonePicker)}>
                    {props.timezonePicker ? IconArrowHeadUp({ fill: branding.sideIconBar.sideIconColorDark }) : IconArrowHeadDown({ fill: branding.sideIconBar.sideIconColorDark })}
                </div>
                <br />
            </TimezonePickerRow>
            {props.timezonePicker && <TimezoneSelectRoot>
                {timezones.map(item => {
                    return <TimezonePickerRow selected={selectedTimezone === item.timezones[0]} onClick={() => onTimezoneSelect(item)}>
                        <p>{item.name}</p>
                        <div style={{ marginLeft: "25px" }}>
                        </div>
                    </TimezonePickerRow>
                })}
            </TimezoneSelectRoot>}
        </>)
}

interface SettingsTabProps {
    logout: (beConnectionToken: string) => void
}

const SettingsTab: React.FunctionComponent<SettingsTabProps> = (props) => {
    const userState = useLoggedInState()
    const languageState = useLanguageState()
    const appState = useAppState()
    const chime = useChimeContext()
    const favoriteState = useFavoriteState()
    const strings = languageState.getStrings()
    const history = useHistory()

    const [showBlocked, setShowBlocked] = useState(false)

    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [timezoneToggle, setTimezoneToggle] = useState<boolean>(appState.timezone === "" || appState.timezone === undefined || appState.timezone === moment.tz.guess())
    const [timezonePicker, setTimezonePicker] = useState<boolean>(false)

    useEffect(() => {
        if (!isLoaded) {
            setIsLoaded(true)
        }
        // eslint-disable-next-line
    }, [userState, isLoaded])

    useEffect(() => {
        if (timezoneToggle === false) {
            setTimezonePicker(false)

        } else {
            appState.setTimeZone(moment.tz.guess())
            setTimezonePicker(false)
        }
    }, [timezoneToggle]) //eslint-disable-line


    function setSettingVisible() {
        (async () => {
            const profileId = userState.user()?.profileId
            if (profileId === undefined) {
                return
            }

            const response = await setUserSetting(profileId as string, SettingsKey.VISIBLE, "" + (!userState.user()!.visible));
            if ((response as BackendServiceError).httpStatus) {
            } else {
                userState.setVisible((response as Settings).visible)
            }
        })()
    }

    function setSettingShowroomStandby() {
        (async () => {
            const profileId = userState.user()?.profileId
            if (profileId === undefined) {
                return
            }

            const response = await setUserSetting(profileId as string, SettingsKey.SHOWROOMSTANDBY, "" + (!userState.user()!.showroomStandby));
            if ((response as BackendServiceError).httpStatus) {
            } else {
                userState.setShowroomStandby((response as Settings).showroomStandby)
            }
        })()
    }


    const logoutUser = async () => {
        const profileId = userState.user()?.profileId
        if (profileId) {
            await updateUserValues({ id: profileId, presenceStatus: PresenceType.OFFWORK, lastConnected: new Date().toISOString() })
            await endSession()
            const resp = await logout(profileId)

            if ((resp as BackendServiceError).httpStatus) {
                // Reset user state, a new appdevice and jwtToken is created
                userState.setUserState({})
            } else {
                const tokenResponse = resp as TokenResponse
                props.logout(tokenResponse.beConnectionToken)

                history.push("/")
            }
        } else {
            // Reset user state, a new appdevice and jwtToken is created
            userState.setUserState({})
        }
        localStorage.removeItem("routeBeforeCall")
        appState.reset()
        favoriteState.reset()
        const chimeName = chime.getName()
        if (chimeName && chimeName.length > 0) {
            chime.leaveRoom()
        }

        const element = document.getElementById("hubspot-messages-iframe-container")
        if (element)
            element.style.cssText += ';right: 0px !important;'
    }


    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const currentUserPosition: string = [userState.user()?.position, userState.user()?.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const corussoftLogo = "/corus_RGB_L_dark.png"

    const onEditProfileClick = () => {
        history.push(myProfilePageRoute)
    }
    return (
        <TabRoot>
            <TabContentArea className="rs-nav-content">
                <SettingsArea>
                    <br />
                    {showBlocked && <>
                        <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => setShowBlocked(false)}>
                            <div>
                                {IconChevronLeft({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                            <p>{strings.communicationArea.blockedContactsTitle}</p>
                            <br />
                        </SettingsAreaRow>
                        <BlockedTab />
                    </>}

                    {!showBlocked && <>
                        <HeaderArea>
                            <AvatarWithPresenceState
                                avatarSize={56}
                                badgeSize={15}
                                showAvatarBadge={true}
                                badgeRight={0.1}
                                badgeTop={37}
                                userId={userState.user()?.profileId || ''}
                                initPresenceByList={false}
                                userType={userState.user()?.type}
                                content={{ pictureUrl: userState.user()?.logoUrl, alt: currentUserName }}>
                            </AvatarWithPresenceState>
                            <div>
                                <CurrentUserName style={{ fontSize: 15 }}>{currentUserName}<br /></CurrentUserName>
                                <CurrentUserPosition style={{ fontSize: 12 }}>{currentUserPosition}</CurrentUserPosition>
                            </div>
                            <div style={{ marginTop: 15, marginLeft: 5, cursor: 'pointer' }} onClick={() => onEditProfileClick()}>
                                {IconEdit({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                        </HeaderArea>
                        <br />
                        <CustomDivider style={{ marginLeft: "0px" }} />
                        <SubTabRoot>
                            <ContentScrollContainer>
                                <br />
                                <NetworkingToggle />
                                <DoNotDisturbToggle />

                                <SettingsAreaRow>
                                    {IconTimeZone({ fill: branding.sideIconBar.sideIconColorDark })}
                                    <p>{strings.communicationArea.timezone}</p>
                                    <ToggleSwitch isEnabled={timezoneToggle} onClick={() => setTimezoneToggle(!timezoneToggle)} />
                                </SettingsAreaRow>
                                <TimezonePicker setTimezonePicker={setTimezonePicker} timezoneToggle={timezoneToggle} timezonePicker={timezonePicker}></TimezonePicker>
                                <CustomDivider />
                                <br />
                                <DeviceSwitcher />
                                <CustomDivider />
                                <br />

                                <SettingsAreaRow style={{ cursor: "pointer" }} onClick={() => setShowBlocked(true)}>
                                    {IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark })}
                                    <p>{strings.communicationArea.blockedContactsTitle}</p>
                                    <div style={{ marginLeft: "25px" }}>
                                        {IconChevronRight({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                                    </div>
                                </SettingsAreaRow>

                                <div hidden={!userState.user()?.organizations || userState.user()?.organizations?.length! === 0}>
                                    <SettingsTitleRow>{strings.communicationArea.myOrganizationTitle}</SettingsTitleRow>
                                    <CustomDivider />

                                    {userState.user()?.organizations && userState.user()?.organizations?.length! > 0 &&
                                        <OrganizationRoot visibility={'visible'} className="d-flex align-items-center justify-content-center">
                                            {userState.user()?.organizations?.map(organization => {
                                                return <DetailNavLink id={organization?.id!} type="organization" name={organization?.name ?? ""} source="PROFILE" width={"100%"}>
                                                    <div className="d-flex align-items-center pt-2 pb-2">
                                                        <OrganizationLogoDiv>
                                                            <img src={organization?.logo!} alt="" />
                                                        </OrganizationLogoDiv>
                                                        <CurrentUserOrganization>{organization.name}</CurrentUserOrganization>
                                                    </div>
                                                </DetailNavLink>
                                            })}
                                        </OrganizationRoot>}
                                    <CustomDivider />
                                    <br />
                                </div>

                                {userState.user()?.organizations && userState.user()?.organizations?.length! > 0 && <SettingsAreaRow>
                                    {userState.user()?.visible ? IconShow({ fill: branding.sideIconBar.sideIconColorDark }) : IconHide({ fill: branding.sideIconBar.sideIconColorDark })}
                                    <p>{strings.communicationArea.showAsContactTitle}</p>
                                    <ToggleSwitch isEnabled={userState.user()?.visible} onClick={() => setSettingVisible()} />
                                </SettingsAreaRow>
                                }
                                {userState.user()?.organizations && userState.user()?.organizations?.length! > 0 && <SettingsAreaRow>
                                    {IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}
                                    <p>{strings.communicationArea.standByForShowroom}</p>
                                    <ToggleSwitch isEnabled={userState.user()?.showroomStandby} onClick={() => setSettingShowroomStandby()} />
                                </SettingsAreaRow>
                                }
                            </ContentScrollContainer>
                        </SubTabRoot>
                        <BottomMenuCompanyLogo>
                            <img style={{ width: "26%", marginTop: "10px", marginBottom: "70px" }} src={corussoftLogo} alt="Corussoft GmbH" />
                            <div style={{ marginLeft: "-100px", fontSize: "14px" }}>Version {version}</div>
                        </BottomMenuCompanyLogo>
                        <LogOutButtonRoot onClick={() => logoutUser()} style={{ position: "absolute", bottom: "80px", cursor: "pointer" }}>
                            <LogOutButton>
                                {strings.communicationArea.logOutTitle}
                            </LogOutButton>
                        </LogOutButtonRoot>
                    </>
                    }

                </SettingsArea>
            </TabContentArea>
        </TabRoot>
    );
};

export default SettingsTab;
