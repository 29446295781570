import { createState, useState, self } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { ChatConversationParam } from '../communicationArea/ChatPage';
import { ScheduleListType } from '../communicationArea/ScheduleTab';
import { NetworkingListType } from '../communicationArea/CommunicationArea';
import { NotificationListType } from '../communicationArea/NotificationsTab';
import { getEnvironment, getEnvironmentShort } from "../environments";

import winston from 'winston';
import { EventDate, Suggestion } from '../backendServices/Types';
import "moment-timezone";
import moment from "moment";

interface State {
    networkingOpen: boolean
    communicationCenterDisplayMode: CommunicationCenterDisplayMode
    communicationCenterDisplayParam?: any
    communicationCenterDisplayParams: { [mode: string]: any }
    forceLoadListFunction?: () => void

    showTourNotification: boolean

    showMissedCallNotification: boolean
    missedCallNotificationName: string
    missedCallNotificationId: string

    showMeetingReminder: boolean
    meetingReminderName: string
    meetingTimeBefore: string

    isMyHandRaised: boolean

    liveStreamingChannel: { id: string, url: string, eventDate?: EventDate } | null
    videoPlayerStatus: { volume?: number, isMuted?: boolean, isPaused?: boolean, timePosition?: number } | null

    isMeetingChatShown: boolean | undefined

    suggestParam?: Suggestion
    suggestSearchVisible: boolean

    refreshLobbyNetworking: boolean
    timezone: string

    currentItem: string
}

export enum CommunicationCenterDisplayMode {
    NETWORKING, CHATS, SCHEDULE, NOTIFICATIONS, SETTINGS,
}

const defaultValues: () => State = () => {
    return {
        networkingOpen: true,
        communicationCenterDisplayMode: CommunicationCenterDisplayMode.NETWORKING,
        communicationCenterDisplayParams: {},
        showTourNotification: true,
        showMissedCallNotification: false,
        missedCallNotificationName: "",
        missedCallNotificationId: "",
        showMeetingReminder: true,
        meetingReminderName: "",
        meetingTimeBefore: "15",
        isMyHandRaised: false,
        liveStreamingChannel: null,
        videoPlayerStatus: null,
        liveStreamingEventDate: null,
        isMeetingChatShown: undefined,
        refreshLobbyNetworking: false,
        timezone: moment.tz.guess(),
        suggestSearchVisible: false,
        currentItem: "home"
    }
}

const customLogFormat = winston.format.printf(({ level, message, errorMessage, errorStack }) => {
    return `{environment: ${getEnvironment()}} ${level}: ${message} ${errorMessage} ${errorStack}`;
});


// Log only to remote on int/stage/live
// Log only error log to remote
const remoteLogger = getEnvironmentShort() !== "dev" ? new winston.transports.Http({ ssl: true, host: `logging${getEnvironmentShort() === "live" ? "" : "-" + getEnvironmentShort()}.event-cloud.com`, port: 443, path: "/", level: 'error', handleExceptions: true }) : undefined;

let logTargets: winston.transport[] = [];
if (remoteLogger !== undefined) {
    logTargets = logTargets.concat(remoteLogger);
}
// Log info and up to console
logTargets = logTargets.concat(new winston.transports.Console({ level: 'info', handleExceptions: true }));

export const defaultLogger = winston.createLogger({
    level: 'info',
    format: winston.format.combine(
        customLogFormat,
        winston.format.prettyPrint()),
    transports: logTargets,
    handleExceptions: true,
    exitOnError: false,
});

/* Doof
export interface logType {
    message: string
    request: string
    params: object
    errorMessage: string
    errorStack: object
}
export function error2(logObject:logType):void { defaultLogger.error(logObject); }
export function warn2(logObject:logType):void { defaultLogger.warn(logObject); }
export function info2(logObject:logType):void { defaultLogger.info(logObject); }
*/



export const useAppState = createState(defaultValues())[self].map(s => () => {
    const state = useState(s)
    state[self].attach(Persistence('virtualGuide-app'))

    const setDisplay = (mode: CommunicationCenterDisplayMode, param?: any | null, forceUpdate?: boolean) => {
        state[self].set(prevState => {
            prevState.networkingOpen = true
            const communicationCenterDisplayDidChange = prevState.communicationCenterDisplayMode !== mode || prevState.communicationCenterDisplayParam !== param
            prevState.communicationCenterDisplayMode = mode
            if (param === undefined) { // use param previously used for this mode
                prevState.communicationCenterDisplayParam = prevState.communicationCenterDisplayParams ? prevState.communicationCenterDisplayParams[mode] : undefined
            }
            else if (param === null) { // don't use any param
                prevState.communicationCenterDisplayParam = undefined
                if (prevState.communicationCenterDisplayParams)
                    prevState.communicationCenterDisplayParams[mode] = undefined
            } else {
                prevState.communicationCenterDisplayParam = param
                if (!prevState.communicationCenterDisplayParams) // for existing local storage values not yet containing this field
                    prevState.communicationCenterDisplayParams = {}
                prevState.communicationCenterDisplayParams[mode] = param
            }
            if (forceUpdate && !communicationCenterDisplayDidChange && prevState.forceLoadListFunction !== undefined) {
                prevState.forceLoadListFunction()
            }

            return prevState
        })

    }

    return ({
        setLiveStreamChannel: (newLiveStreamingChannel: { id: string, url: string, eventDate?: EventDate } | null) => {
            return state[self].set(prevState => {
                prevState.liveStreamingChannel = newLiveStreamingChannel
                return prevState
            })
        },
        liveStreamChannel: state[self].get().liveStreamingChannel,
        setVideoPlayerStatus: (newVideoPlayerStatus: { volume?: number, isMuted?: boolean, isPaused?: boolean, timePosition?: number } | null) => {
            return state[self].set(prevState => {
                prevState.videoPlayerStatus = newVideoPlayerStatus
                return prevState
            })
        },
        videoPlayerStatus: state[self].get().videoPlayerStatus,
        isNetworkingOpen: () => {
            // const element = document.getElementById("hubspot-messages-iframe-container")  // WARNING! Saved code. Behaviour still not 100% defined
            // if (element) {
            //     if (window.location.pathname !== "/help") {
            //         element.style.cssText += ';display: none !important;';
            //     }
            // }
            // WHY IS AN ELEMENT STYLED IN THIS FUNCTION?
            const element = document.getElementById("hubspot-messages-iframe-container")
            if (element)
                element.style.cssText += state[self].get().networkingOpen ? ';right: 340px !important;bottom: 60px !important;' : ';right: 80px !important;bottom: 60px !important;'
            return state[self].get().networkingOpen
        },
        toggleNetworking: () => {
            return state[self].set(prevState => {
                prevState.networkingOpen = !prevState.networkingOpen
                return prevState
            })
        },
        communicationCenterDisplayMode: state[self].get().communicationCenterDisplayMode,
        communicationCenterDisplayParam: state[self].get().communicationCenterDisplayParam,
        setCommunicationCenterDisplayMode: (mode: CommunicationCenterDisplayMode) => {
            setDisplay(mode)
        },
        setShowPeopleTab: (param?: NetworkingListType) => {
            setDisplay(CommunicationCenterDisplayMode.NETWORKING, param)
        },
        setShowChatsTab: (param?: ChatConversationParam | null) => {
            setDisplay(CommunicationCenterDisplayMode.CHATS, param)
        },
        setShowScheduleTab: (param: ScheduleListType) => {
            setDisplay(CommunicationCenterDisplayMode.SCHEDULE, param, true)
        },
        setShowNotificationTab: (param: NotificationListType) => {
            setDisplay(CommunicationCenterDisplayMode.NOTIFICATIONS, param)
        },
        setShowSettingsTab: () => {
            setDisplay(CommunicationCenterDisplayMode.SETTINGS)
        },
        setCommunicationCenterForceLoadListFunction: (forceLoadListFunction: () => void) => {
            return state[self].set(prevState => {
                prevState.forceLoadListFunction = forceLoadListFunction
                return prevState
            })
        },
        showTourNotification: state[self].get().showTourNotification,
        setShowTourNotification: (show: boolean) => {
            return state[self].set(prevState => {
                prevState.showTourNotification = show
                return prevState
            })
        },
        showMissedCallNotification: state[self].get().showMissedCallNotification,
        missedCallNotificationName: state[self].get().missedCallNotificationName,
        missedCallNotificationId: state[self].get().missedCallNotificationId,

        setMissedCallNotification: (show: boolean, id: string, name: string) => {
            return state[self].set(prevState => {
                prevState.missedCallNotificationId = id
                prevState.missedCallNotificationName = name
                prevState.showMissedCallNotification = show
                return prevState
            })
        },

        showMeetingReminder: state[self].get().showMeetingReminder,
        meetingReminderName: state[self].get().meetingReminderName,
        meetingTimeBefore: state[self].get().meetingTimeBefore,

        setMeetingReminder: (show: boolean, name: string, timeBefore: string) => {
            return state[self].set(prevState => {
                prevState.meetingReminderName = name
                prevState.showMeetingReminder = show
                prevState.meetingTimeBefore = timeBefore
                return prevState
            })
        },

        isMyHandRaised: state[self].get().isMyHandRaised,

        setIsMyHandRaised: (raise: boolean) => {
            return state[self].set(prevState => {
                prevState.isMyHandRaised = raise
                return prevState
            })
        },

        isMeetingChatShown: state[self].get().isMeetingChatShown,

        setIsMeetingChatShown: (show: boolean) => {
            return state[self].set(prevState => {
                prevState.isMeetingChatShown = show
                return prevState
            })
        },

        timezone: state[self].get().timezone,

        setTimeZone: (tz: string) => {
            return state[self].set(prevState => {
                prevState.timezone = tz
                return prevState
            })
        },

        currentItem: state[self].get().currentItem,

        setCurrentItem: (currentItem: string) => {
            return state[self].set(prevState => {
                prevState.currentItem = currentItem
                return prevState
            })
        },
        reset: () => {
            return state[self].set(prevState => {
                prevState = defaultValues()
                return prevState
            })
        },

        setSuggestParam: (suggestion: Suggestion) => {
            return state[self].set(prevState => {
                prevState.suggestParam = suggestion
                return prevState
            })
        },

        clearSuggestParam: () => {
            return state[self].set(prevState => {
                prevState.suggestParam = undefined
                return prevState
            })
        },

        getSuggestParam: () => {
            return state[self].get().suggestParam
        },

        setSuggestSearchVisible: (visible: boolean) => {
            if (state[self].get().suggestSearchVisible === visible)
                return state[self].get().suggestSearchVisible
            return state[self].set(prevState => {
                prevState.suggestSearchVisible = visible
                return prevState
            })
        },

        isSuggestSuggestSearchVisible: () => {
            return state[self].get().suggestSearchVisible
        },

        refreshToggleLobbyNetworking: () => {
            return state[self].set(prevState => {
                prevState.refreshLobbyNetworking = !prevState.refreshLobbyNetworking
                return prevState
            })
        },

        lobbyNetworking: state[self].get().refreshLobbyNetworking
    })
})
