/*********************************************************************************************
 * Auto generated code * do not edit manually                                                *
**********************************************************************************************/
import branding, {LocalizedString} from './branding/branding';
import { MeetingRoomGroupType } from './conference/ConferencePageContent';
import { FillerType, CategoryFilterType } from './contentArea/ExhibitorsPageContent';
import { HelpTileType, HelpLinkType } from './contentArea/HelpPageContent';
import { PressItemBranding, MagazineItemBranding } from './contentArea/pressMedia/PressMediaPageContent';
import { OffersNeedsCategoryType } from './contentArea/myprofile/MyProfilePageContent' 
import { GroupConfig } from './backendServices/BackendServices'




type Lang = "de" | "en"

function createMeetingRoomGroups(groups: MeetingRoomGroupType[], lang: Lang) {
  return groups.map((group) => {
    return {
      id: group.id,
      isPrivate: group.isPrivate,
      title: group.title[lang],
      titleVisible: group.titleVisible,
      organizationName: group.organizationName?.[lang],
      organizationId: group.organizationId,
      secondOrganizationId: group.secondOrganizationId,
      description: group.description?.[lang],
      pictureUrl: group.pictureUrl,
      bottomExtraOverlay: group.bottomExtraOverlay,
      meetingRooms: group.meetingRooms.map((room) => {
        return {
          id: room.id,
          title: room.title[lang],
          titleVisible: room.titleVisible,
          description: room.description?.[lang],
          pictureUrl: room.pictureUrl
        }
      })
    }
  })
}

function createMyProfilePageContentOffersNeedsCategories(categories: OffersNeedsCategoryType[], lang: Lang) {
  return categories.map((category: OffersNeedsCategoryType) => {
    return {
     id: category.id,
     name: category.name[lang],
     placeholderBuying: category.placeholderBuying[lang],
     placeholderSelling: category.placeholderSelling[lang]
    }
  })
}


function createExhibitorsPageContentFillersData(fillers: FillerType[], lang: Lang) {
  return fillers.map((filler: FillerType) => {
    return {
      title: filler.title[lang],
      subtitle: filler.subtitle[lang],
      link: filler.link[lang],
      to: filler.to[lang],
    }
  })
}

function createExhibitorsPageContentFillersDataStandard(fillers: FillerType[], lang: Lang) {
  return createExhibitorsPageContentFillersData(fillers, lang)
}

function createExhibitorsPageContentFillersDataBasic(fillers: FillerType[], lang: Lang) {
  return createExhibitorsPageContentFillersData(fillers, lang)
}

function createExhibitorsPageContentFillersDataNone(fillers: FillerType[], lang: Lang) {
  return createExhibitorsPageContentFillersData(fillers, lang)
}

function createExhibitorsPageContentFirstFilterCategories(items: CategoryFilterType[], lang: Lang) {
  return items.map((item: CategoryFilterType) => {
    return {
      alias: item.alias,
      name: item.name[lang]
    }
  })
}

function createExhibitorsPageContentSecondFilterCategories(items: CategoryFilterType[], lang: Lang) {
  return items.map((item: CategoryFilterType) => {
    return {
      alias: item.alias,
      name: item.name[lang]
    }
  })
}

function createHelpPageHelpPageItems(items: HelpTileType[], lang: Lang) {
  return items.map((item: HelpTileType) => {
    return {
      title: item.title[lang],
      items: item.items.map((it: HelpLinkType) => {
        return {
            title: it.title[lang],
            link: it.link[lang]
        }
      })
    }
  })
}

function createPressMediaPageContentPressContent(items: PressItemBranding[], lang: Lang) {
  return items.map((item: PressItemBranding) => {
    return {
      title: item.title[lang],
      backgroundImageUrl: item.backgroundImageUrl,
      organization: item.organization,
      linkInfo: {
        isExternalLink: item.linkInfo.isExternalLink,
        link: item.linkInfo.link[lang]
      }
    }
  })
}

function createPressMediaPageContentMagazineContent(items: MagazineItemBranding[], lang: Lang) {
  return items.map((item: MagazineItemBranding) => {
    return {
      title: item.title[lang],
      organization: item.organization,
      link: item.link[lang],
      releaseDate: item.releaseDate,
      backgroundImageUrl: item.backgroundImageUrl
    }
  })
}

function createProgramScheduleBadgeFilterList(items: {label: LocalizedString, value: string}[], lang: Lang) {
  return items.map((item: {label: LocalizedString, value: string}) => {
    return {
      label: item.label[lang],
      value: item.value
    }
  })
}

function createProgramScheduleCategoriesFilterList(items: {label: LocalizedString, value: string}[], lang: Lang) {
  return items.map((item: {label: LocalizedString, value: string}) => {
    return {
      label: item.label[lang],
      value: item.value
    }
  })
}

function createExhibitorsPageContentSuggestGroups(items: GroupConfig[], lang: Lang) {
  return items.map((item: GroupConfig) => {
    return {
      id: item.id,
      type: item.type,
      entityType: item.entityType,
      msc: item.msc,
      showMoreTitle: item.showMoreTitle[lang]
    }
  })
}





function createconfiguration(lang: Lang) {
	return {
  ogTitle: branding.configuration.ogTitle?.[lang],
  ogDescription: branding.configuration.ogDescription?.[lang]
}
}

function createeventTiming(lang: Lang) {
	return {
  eventDaysFormatPattern: branding.eventTiming.eventDaysFormatPattern?.[lang],
  eventDaysFormatPatternShort: branding.eventTiming.eventDaysFormatPatternShort?.[lang],
  eventDaysFormatPatternWithTime: branding.eventTiming.eventDaysFormatPatternWithTime?.[lang],
  eventDaysFormatPatternEvtDetailPage: branding.eventTiming.eventDaysFormatPatternEvtDetailPage?.[lang]
}
}

function createpress(lang: Lang) {
	return branding.press?.[lang]
}

function createglobalStatePopupTexts(lang: Lang) {
	return {
  errorNoCameraPermission: branding.globalStatePopupTexts.errorNoCameraPermission?.[lang],
  errorNoScreenCapturePermission: branding.globalStatePopupTexts.errorNoScreenCapturePermission?.[lang]
}
}

function createglobalTexts(lang: Lang) {
	return {
  usersOnline: branding.globalTexts.usersOnline?.[lang],
  usersOnPage: branding.globalTexts.usersOnPage?.[lang],
  privacyPolicyTitle: branding.globalTexts.privacyPolicyTitle?.[lang],
  privacyPolicyText: branding.globalTexts.privacyPolicyText?.[lang],
  privacyPolicyEndLink: branding.globalTexts.privacyPolicyEndLink?.[lang],
  privacyPolicyEndLinkText: branding.globalTexts.privacyPolicyEndLinkText?.[lang],
  privacyPolicyBoldText: branding.globalTexts.privacyPolicyBoldText?.[lang],
  privacyPolicyAgreeText: branding.globalTexts.privacyPolicyAgreeText?.[lang],
  privacyPolicyDisagreeText: branding.globalTexts.privacyPolicyDisagreeText?.[lang],
  privacyPolicyCheckboxLabel: branding.globalTexts.privacyPolicyCheckboxLabel?.[lang],
  cancel: branding.globalTexts.cancel?.[lang],
  confirm: branding.globalTexts.confirm?.[lang],
  requestAccess: branding.globalTexts.requestAccess?.[lang],
  loadingMessage: branding.globalTexts.loadingMessage?.[lang],
  requestSent: branding.globalTexts.requestSent?.[lang],
  statusRequested: branding.globalTexts.statusRequested?.[lang],
  statusGranted: branding.globalTexts.statusGranted?.[lang],
  accessRequestTitle: branding.globalTexts.accessRequestTitle?.[lang],
  requestReasonText: branding.globalTexts.requestReasonText?.[lang],
  descriptionText: branding.globalTexts.descriptionText?.[lang],
  noAccessMessage: branding.globalTexts.noAccessMessage?.[lang],
  guestRestrictionMessage: branding.globalTexts.guestRestrictionMessage?.[lang],
  hostedByText: branding.globalTexts.hostedByText?.[lang],
  currentSearchLabel: branding.globalTexts.currentSearchLabel?.[lang],
  globalSearchTitle: branding.globalTexts.globalSearchTitle?.[lang]
}
}

function createticketSale(lang: Lang) {
	return {
  getYourTicketButton: branding.ticketSale.getYourTicketButton?.[lang],
  getYourTicketNowButton: branding.ticketSale.getYourTicketNowButton?.[lang],
  getToServiceButton: branding.ticketSale.getToServiceButton?.[lang],
  leadDescriptionText: branding.ticketSale.leadDescriptionText?.[lang],
  commingText: branding.ticketSale.commingText?.[lang],
  checkYourSystemDescriptionText: branding.ticketSale.checkYourSystemDescriptionText?.[lang],
  checkYourSystemButton: branding.ticketSale.checkYourSystemButton?.[lang],
  descriptionTextWhenCheckSystemIsDisabled: branding.ticketSale.descriptionTextWhenCheckSystemIsDisabled?.[lang],
  imprintLink: branding.ticketSale.imprintLink?.[lang],
  imprintLinkUrl: branding.ticketSale.imprintLinkUrl?.[lang],
  ticketsUrl: branding.ticketSale.ticketsUrl?.[lang],
  serviceUrl: branding.ticketSale.serviceUrl?.[lang],
  descriptionTitleForGetTicketLabel: branding.ticketSale.descriptionTitleForGetTicketLabel?.[lang],
  descriptionTitleForGetServiceLabel: branding.ticketSale.descriptionTitleForGetServiceLabel?.[lang]
}
}

function createloginRegistrationSite(lang: Lang) {
	return {
  submitEmailButton: branding.loginRegistrationSite.submitEmailButton?.[lang],
  emailPlaceHolder: branding.loginRegistrationSite.emailPlaceHolder?.[lang],
  passwordPlaceHolder: branding.loginRegistrationSite.passwordPlaceHolder?.[lang],
  submitTokenButton: branding.loginRegistrationSite.submitTokenButton?.[lang],
  tokenPlaceholder: branding.loginRegistrationSite.tokenPlaceholder?.[lang],
  invalidToken: branding.loginRegistrationSite.invalidToken?.[lang],
  wrongToken: branding.loginRegistrationSite.wrongToken?.[lang],
  createNewToken: branding.loginRegistrationSite.createNewToken?.[lang],
  noToken: branding.loginRegistrationSite.noToken?.[lang],
  resendToken: branding.loginRegistrationSite.resendToken?.[lang],
  ticketPanel: branding.loginRegistrationSite.ticketPanel?.[lang],
  buyTicketButton: branding.loginRegistrationSite.buyTicketButton?.[lang],
  changeEmailButton: branding.loginRegistrationSite.changeEmailButton?.[lang],
  createTokenFailed: branding.loginRegistrationSite.createTokenFailed?.[lang],
  createTokenSuccess: branding.loginRegistrationSite.createTokenSuccess?.[lang],
  dataPrivacyPanel: branding.loginRegistrationSite.dataPrivacyPanel?.[lang],
  dataPolicy: branding.loginRegistrationSite.dataPolicy?.[lang],
  invalidEmailPanel: branding.loginRegistrationSite.invalidEmailPanel?.[lang],
  invalidEmailPanelWithLink: {
    befLinkPart: branding.loginRegistrationSite.invalidEmailPanelWithLink.befLinkPart?.[lang],
    linkPart: {
      link: branding.loginRegistrationSite.invalidEmailPanelWithLink.linkPart.link?.[lang],
      text: branding.loginRegistrationSite.invalidEmailPanelWithLink.linkPart.text?.[lang]
    },
    aftLinkPart: branding.loginRegistrationSite.invalidEmailPanelWithLink.aftLinkPart?.[lang]
  },
  outdatedPrivacyDocsPanel: branding.loginRegistrationSite.outdatedPrivacyDocsPanel?.[lang],
  wrongPasswordPanel: branding.loginRegistrationSite.wrongPasswordPanel?.[lang],
  registerEmailFailed: branding.loginRegistrationSite.registerEmailFailed?.[lang],
  thirdPartyUserNotFound: branding.loginRegistrationSite.thirdPartyUserNotFound?.[lang],
  forgotPassword: branding.loginRegistrationSite.forgotPassword?.[lang],
  enterEmail: branding.loginRegistrationSite.enterEmail?.[lang],
  enterPassword: branding.loginRegistrationSite.enterPassword?.[lang],
  enterToken: branding.loginRegistrationSite.enterToken?.[lang],
  centerLoginStyleTitle: branding.loginRegistrationSite.centerLoginStyleTitle?.[lang],
  centerLoginStyleTitleCode: branding.loginRegistrationSite.centerLoginStyleTitleCode?.[lang],
  loginRegistrationTitle: branding.loginRegistrationSite.loginRegistrationTitle?.[lang],
  loginRegistrationSubtitle: branding.loginRegistrationSite.loginRegistrationSubtitle?.[lang],
  passwordForgottenUrl: branding.loginRegistrationSite.passwordForgottenUrl?.[lang],
  centerLoginStyleSubtitle: branding.loginRegistrationSite.centerLoginStyleSubtitle?.[lang],
  centerLoginStyleTicketLink: branding.loginRegistrationSite.centerLoginStyleTicketLink?.[lang],
  logoutReasonSessionTimeout: branding.loginRegistrationSite.logoutReasonSessionTimeout?.[lang],
  logoutReasonForbidden: branding.loginRegistrationSite.logoutReasonForbidden?.[lang],
  contactUsLabel: branding.loginRegistrationSite.contactUsLabel?.[lang],
  termsOfUseText: branding.loginRegistrationSite.termsOfUseText?.[lang],
  termsOfUseUrl: branding.loginRegistrationSite.termsOfUseUrl?.[lang],
  notSupportedScreenSize: branding.loginRegistrationSite.notSupportedScreenSize?.[lang],
  imprintLink: branding.loginRegistrationSite.imprintLink?.[lang],
  imprintLinkUrl: branding.loginRegistrationSite.imprintLinkUrl?.[lang],
  privacyPolicyLink: branding.loginRegistrationSite.privacyPolicyLink?.[lang],
  privacyPolicyLinkUrl: branding.loginRegistrationSite.privacyPolicyLinkUrl?.[lang]
}
}

function createconferenceTexts(lang: Lang) {
	return {
  noAttendees: branding.conferenceTexts.noAttendees?.[lang],
  mute: branding.conferenceTexts.mute?.[lang],
  unmute: branding.conferenceTexts.unmute?.[lang],
  mutedByMod: branding.conferenceTexts.mutedByMod?.[lang],
  closeMutedBy: branding.conferenceTexts.closeMutedBy?.[lang],
  soundOn: branding.conferenceTexts.soundOn?.[lang],
  soundOff: branding.conferenceTexts.soundOff?.[lang],
  videoOn: branding.conferenceTexts.videoOn?.[lang],
  videoOff: branding.conferenceTexts.videoOff?.[lang],
  handRaised: branding.conferenceTexts.handRaised?.[lang],
  raiseHand: branding.conferenceTexts.raiseHand?.[lang],
  lowerHand: branding.conferenceTexts.lowerHand?.[lang],
  shareScreenStart: branding.conferenceTexts.shareScreenStart?.[lang],
  shareScreenStop: branding.conferenceTexts.shareScreenStop?.[lang],
  currentlySharingScreen: branding.conferenceTexts.currentlySharingScreen?.[lang],
  openChat: branding.conferenceTexts.openChat?.[lang],
  leave: branding.conferenceTexts.leave?.[lang],
  incomingCall: branding.conferenceTexts.incomingCall?.[lang],
  outgoingCall: branding.conferenceTexts.outgoingCall?.[lang],
  acceptCall: branding.conferenceTexts.acceptCall?.[lang],
  declineCall: branding.conferenceTexts.declineCall?.[lang],
  cancelCall: branding.conferenceTexts.cancelCall?.[lang],
  callWith: branding.conferenceTexts.callWith?.[lang],
  settings: branding.conferenceTexts.settings?.[lang],
  changingRoomConfirmationTitle: branding.conferenceTexts.changingRoomConfirmationTitle?.[lang],
  changingRoomConfirmationAccept: branding.conferenceTexts.changingRoomConfirmationAccept?.[lang],
  changingRoomConfirmationText: branding.conferenceTexts.changingRoomConfirmationText?.[lang],
  maxAttendees: branding.conferenceTexts.maxAttendees?.[lang],
  disconnected: branding.conferenceTexts.disconnected?.[lang],
  timeUp: branding.conferenceTexts.timeUp?.[lang],
  genericError: branding.conferenceTexts.genericError?.[lang],
  kickConfirmationHeader: branding.conferenceTexts.kickConfirmationHeader?.[lang],
  banConfirmationHeader: branding.conferenceTexts.banConfirmationHeader?.[lang],
  kickBanConfirmationBody: branding.conferenceTexts.kickBanConfirmationBody?.[lang],
  statusBanned: branding.conferenceTexts.statusBanned?.[lang],
  statusKicked: branding.conferenceTexts.statusKicked?.[lang],
  kick: branding.conferenceTexts.kick?.[lang],
  kickHint: branding.conferenceTexts.kickHint?.[lang],
  ban: branding.conferenceTexts.ban?.[lang],
  banHint: branding.conferenceTexts.banHint?.[lang],
  moderator: branding.conferenceTexts.moderator?.[lang],
  chatSwitch: branding.conferenceTexts.chatSwitch?.[lang],
  participantsSwitch: branding.conferenceTexts.participantsSwitch?.[lang],
  searchForParticipantsPlaceholder: branding.conferenceTexts.searchForParticipantsPlaceholder?.[lang],
  addParticipantsText: branding.conferenceTexts.addParticipantsText?.[lang],
  connectWithAllParticipantsText: branding.conferenceTexts.connectWithAllParticipantsText?.[lang],
  statusLive: branding.conferenceTexts.statusLive?.[lang],
  timerRemaining: branding.conferenceTexts.timerRemaining?.[lang],
  timerUpAlert: branding.conferenceTexts.timerUpAlert?.[lang],
  postEventPhaseMessage: branding.conferenceTexts.postEventPhaseMessage?.[lang],
  showroomTitle: branding.conferenceTexts.showroomTitle?.[lang],
  showroomUnknownCompanyText: branding.conferenceTexts.showroomUnknownCompanyText?.[lang],
  masterclassTitle: branding.conferenceTexts.masterclassTitle?.[lang],
  greenRoomTitle: branding.conferenceTexts.greenRoomTitle?.[lang],
  roundtableTitle: branding.conferenceTexts.roundtableTitle?.[lang],
  onAirMessage: branding.conferenceTexts.onAirMessage?.[lang],
  liveInMessage: branding.conferenceTexts.liveInMessage?.[lang],
  roomLockedTitle: branding.conferenceTexts.roomLockedTitle?.[lang],
  currentlyNotLiveMessage: branding.conferenceTexts.currentlyNotLiveMessage?.[lang],
  gettingLiveInMessage: branding.conferenceTexts.gettingLiveInMessage?.[lang]
}
}

function createmeetingRoomGroups(lang: Lang) {
	return createMeetingRoomGroups(branding.meetingRoomGroups, lang)
}

function createreceptionPage(lang: Lang) {
	return {
  networkingBannerTitle: branding.receptionPage.networkingBannerTitle?.[lang],
  eventKeyLobby: branding.receptionPage.eventKeyLobby?.[lang],
  receptionPageLobbyHeaderTitle: branding.receptionPage.receptionPageLobbyHeaderTitle?.[lang],
  receptionPageLobbyHeaderDescription: branding.receptionPage.receptionPageLobbyHeaderDescription?.[lang],
  receptionPageMyHeaderTitle: branding.receptionPage.receptionPageMyHeaderTitle?.[lang],
  receptionPageMyHeaderDescriptionFirstPart: branding.receptionPage.receptionPageMyHeaderDescriptionFirstPart?.[lang],
  receptionPageMyHeaderDescriptionSecondPart: branding.receptionPage.receptionPageMyHeaderDescriptionSecondPart?.[lang],
  receptionPageMyHeaderDescriptionThirdPart: branding.receptionPage.receptionPageMyHeaderDescriptionThirdPart?.[lang],
  receptionPageTitleDescription: branding.receptionPage.receptionPageTitleDescription?.[lang],
  visitMyProfile: branding.receptionPage.visitMyProfile?.[lang],
  viewMySchedule: branding.receptionPage.viewMySchedule?.[lang],
  sponsorsTitle: branding.receptionPage.sponsorsTitle?.[lang],
  liveSessions: branding.receptionPage.liveSessions?.[lang],
  lobbyText: branding.receptionPage.lobbyText?.[lang],
  speakers: branding.receptionPage.speakers?.[lang],
  speakersNavLinkText: branding.receptionPage.speakersNavLinkText?.[lang],
  speakersBookmarkedNavLinkText: branding.receptionPage.speakersBookmarkedNavLinkText?.[lang],
  featuredCompanies: branding.receptionPage.featuredCompanies?.[lang],
  liveSessionsNavLinkText: branding.receptionPage.liveSessionsNavLinkText?.[lang],
  virtualCaffeNavLinkText: branding.receptionPage.virtualCaffeNavLinkText?.[lang],
  showFloorNavLinkText: branding.receptionPage.showFloorNavLinkText?.[lang],
  productsNavLinkText: branding.receptionPage.productsNavLinkText?.[lang],
  trademarksNavLinkText: branding.receptionPage.trademarksNavLinkText?.[lang],
  showFloorBookmarkedNavLinkText: branding.receptionPage.showFloorBookmarkedNavLinkText?.[lang],
  networkingNavLinkText: branding.receptionPage.networkingNavLinkText?.[lang],
  participantsNavLinkText: branding.receptionPage.participantsNavLinkText?.[lang],
  attendes: branding.receptionPage.attendes?.[lang],
  sessions: branding.receptionPage.sessions?.[lang],
  viewAll: branding.receptionPage.viewAll?.[lang],
  mySchedule: branding.receptionPage.mySchedule?.[lang],
  exhibitors: branding.receptionPage.exhibitors?.[lang],
  noEventsScheduled: branding.receptionPage.noEventsScheduled?.[lang],
  differentCountries: branding.receptionPage.differentCountries?.[lang],
  businessPossibilities: branding.receptionPage.businessPossibilities?.[lang],
  liveEvents: branding.receptionPage.liveEvents?.[lang],
  masterclasses: branding.receptionPage.masterclasses?.[lang],
  hoursOfProgram: branding.receptionPage.hoursOfProgram?.[lang],
  noBookmarkedSpeakers: branding.receptionPage.noBookmarkedSpeakers?.[lang],
  noBookmarkedExhibitors: branding.receptionPage.noBookmarkedExhibitors?.[lang],
  joinButton: branding.receptionPage.joinButton?.[lang],
  networkingButton: branding.receptionPage.networkingButton?.[lang],
  networkingText1: branding.receptionPage.networkingText1?.[lang],
  networkingText2: branding.receptionPage.networkingText2?.[lang],
  hoursRemaining: branding.receptionPage.hoursRemaining?.[lang],
  liveTomorrow: branding.receptionPage.liveTomorrow?.[lang],
  nowLive: branding.receptionPage.nowLive?.[lang],
  liveOn: branding.receptionPage.liveOn?.[lang],
  visitExhibitorText: branding.receptionPage.visitExhibitorText?.[lang],
  visitProductText: branding.receptionPage.visitProductText?.[lang],
  visitTrademarkText: branding.receptionPage.visitTrademarkText?.[lang]
}
}

function createerrorSite(lang: Lang) {
	return {
  reloadButton: branding.errorSite.reloadButton?.[lang],
  errorTitle: branding.errorSite.errorTitle?.[lang],
  errorDescription: branding.errorSite.errorDescription?.[lang]
}
}

function createcommunicationArea(lang: Lang) {
	return {
  communicationAreaTitle: branding.communicationArea.communicationAreaTitle?.[lang],
  reloadButtonText: branding.communicationArea.reloadButtonText?.[lang],
  relevantListErrorMessage: branding.communicationArea.relevantListErrorMessage?.[lang],
  requestListErrorMessage: branding.communicationArea.requestListErrorMessage?.[lang],
  contactListErrorMessage: branding.communicationArea.contactListErrorMessage?.[lang],
  chatListErrorMessage: branding.communicationArea.chatListErrorMessage?.[lang],
  chatErrorMessage: branding.communicationArea.chatErrorMessage?.[lang],
  chatMessageInputHint: branding.communicationArea.chatMessageInputHint?.[lang],
  chatStartNewChat: branding.communicationArea.chatStartNewChat?.[lang],
  chatToolFavorite: branding.communicationArea.chatToolFavorite?.[lang],
  chatToolCalendarEntry: branding.communicationArea.chatToolCalendarEntry?.[lang],
  chatToolVideoChat: branding.communicationArea.chatToolVideoChat?.[lang],
  chatToolRecommend: branding.communicationArea.chatToolRecommend?.[lang],
  chatToolMute: branding.communicationArea.chatToolMute?.[lang],
  chatToolUnmute: branding.communicationArea.chatToolUnmute?.[lang],
  chatToolBlock: branding.communicationArea.chatToolBlock?.[lang],
  relevantTabEmpty: branding.communicationArea.relevantTabEmpty?.[lang],
  requestsTabEmpty: branding.communicationArea.requestsTabEmpty?.[lang],
  contactsTabEmpty: branding.communicationArea.contactsTabEmpty?.[lang],
  blockedTabEmpty: branding.communicationArea.blockedTabEmpty?.[lang],
  allChatsTabEmpty: branding.communicationArea.allChatsTabEmpty?.[lang],
  contactChatsTabEmpty: branding.communicationArea.contactChatsTabEmpty?.[lang],
  scheduleTabEmpty: branding.communicationArea.scheduleTabEmpty?.[lang],
  scheduleMeetingText: branding.communicationArea.scheduleMeetingText?.[lang],
  scheduleTabNoResult: branding.communicationArea.scheduleTabNoResult?.[lang],
  relevantTabTitle: branding.communicationArea.relevantTabTitle?.[lang],
  requestsTabTitle: branding.communicationArea.requestsTabTitle?.[lang],
  contactsTabTitle: branding.communicationArea.contactsTabTitle?.[lang],
  peopleTabTitle: branding.communicationArea.peopleTabTitle?.[lang],
  chatTabTitle: branding.communicationArea.chatTabTitle?.[lang],
  callsTabTitle: branding.communicationArea.callsTabTitle?.[lang],
  notificationsTabTitle: branding.communicationArea.notificationsTabTitle?.[lang],
  allCallsTabTitle: branding.communicationArea.allCallsTabTitle?.[lang],
  missedCallsTabTitle: branding.communicationArea.missedCallsTabTitle?.[lang],
  scheduleTabTitle: branding.communicationArea.scheduleTabTitle?.[lang],
  allPeopleTabTitle: branding.communicationArea.allPeopleTabTitle?.[lang],
  allChatsTabTitle: branding.communicationArea.allChatsTabTitle?.[lang],
  contactChatsTabTitle: branding.communicationArea.contactChatsTabTitle?.[lang],
  blockedChatsTabTitle: branding.communicationArea.blockedChatsTabTitle?.[lang],
  allScheduleTabTitle: branding.communicationArea.allScheduleTabTitle?.[lang],
  pendingScheduleTabTitle: branding.communicationArea.pendingScheduleTabTitle?.[lang],
  declinedScheduleTabTitle: branding.communicationArea.declinedScheduleTabTitle?.[lang],
  silentChat: branding.communicationArea.silentChat?.[lang],
  settingsTitle: branding.communicationArea.settingsTitle?.[lang],
  closeSettingsTitle: branding.communicationArea.closeSettingsTitle?.[lang],
  onSign: branding.communicationArea.onSign?.[lang],
  offSign: branding.communicationArea.offSign?.[lang],
  chatOnOffTitle: branding.communicationArea.chatOnOffTitle?.[lang],
  audioVideoActivateTitle: branding.communicationArea.audioVideoActivateTitle?.[lang],
  noSearchResultsTitle: branding.communicationArea.noSearchResultsTitle?.[lang],
  scheduleListSectionHeaderAccepted: branding.communicationArea.scheduleListSectionHeaderAccepted?.[lang],
  scheduleListSectionHeaderPast: branding.communicationArea.scheduleListSectionHeaderPast?.[lang],
  scheduleListSectionHeaderRequests: branding.communicationArea.scheduleListSectionHeaderRequests?.[lang],
  scheduleListSectionHeaderDeclined: branding.communicationArea.scheduleListSectionHeaderDeclined?.[lang],
  scheduleListSectionHeaderNow: branding.communicationArea.scheduleListSectionHeaderNow?.[lang],
  personCompanyLink: branding.communicationArea.personCompanyLink?.[lang],
  blockedContactsTitle: branding.communicationArea.blockedContactsTitle?.[lang],
  myOrganizationTitle: branding.communicationArea.myOrganizationTitle?.[lang],
  showAsContactTitle: branding.communicationArea.showAsContactTitle?.[lang],
  standByForShowroom: branding.communicationArea.standByForShowroom?.[lang],
  logOutTitle: branding.communicationArea.logOutTitle?.[lang],
  livechat: branding.communicationArea.livechat?.[lang],
  poll: branding.communicationArea.poll?.[lang],
  qanda: branding.communicationArea.qanda?.[lang],
  relevantTabRelevantSectionTitle: branding.communicationArea.relevantTabRelevantSectionTitle?.[lang],
  relevantTabAllSectionTitle: branding.communicationArea.relevantTabAllSectionTitle?.[lang],
  requestsTabAwaitingReplySectionTitle: branding.communicationArea.requestsTabAwaitingReplySectionTitle?.[lang],
  requestsTabMyOrganizationRequestsSectionTitle: branding.communicationArea.requestsTabMyOrganizationRequestsSectionTitle?.[lang],
  requestsTabMyPendingRequestsSectionTitle: branding.communicationArea.requestsTabMyPendingRequestsSectionTitle?.[lang],
  requestsTabMyExhibitorRequestsSectionTitle: branding.communicationArea.requestsTabMyExhibitorRequestsSectionTitle?.[lang],
  contactsTabFavoritesSectionTitle: branding.communicationArea.contactsTabFavoritesSectionTitle?.[lang],
  contactsTabMyPersonalContactsSectionTitle: branding.communicationArea.contactsTabMyPersonalContactsSectionTitle?.[lang],
  contactsTabMyOrganizationsContactsSectionTitle: branding.communicationArea.contactsTabMyOrganizationsContactsSectionTitle?.[lang],
  requestAcceptTitle: branding.communicationArea.requestAcceptTitle?.[lang],
  requestIgnoreTitle: branding.communicationArea.requestIgnoreTitle?.[lang],
  requestDeclineTitle: branding.communicationArea.requestDeclineTitle?.[lang],
  exportVCards: branding.communicationArea.exportVCards?.[lang],
  exportCsv: branding.communicationArea.exportCsv?.[lang],
  exportICals: branding.communicationArea.exportICals?.[lang],
  blockingSectionTitle: branding.communicationArea.blockingSectionTitle?.[lang],
  blockedSectionTitle: branding.communicationArea.blockedSectionTitle?.[lang],
  userReportText: branding.communicationArea.userReportText?.[lang],
  claimAccountText: branding.communicationArea.claimAccountText?.[lang],
  sendReportText: branding.communicationArea.sendReportText?.[lang],
  reportDescriptionText: branding.communicationArea.reportDescriptionText?.[lang],
  reportText: branding.communicationArea.reportText?.[lang],
  reportHint: branding.communicationArea.reportHint?.[lang],
  incomingText: branding.communicationArea.incomingText?.[lang],
  outcomingText: branding.communicationArea.outcomingText?.[lang],
  notificationRequestTabTitle: branding.communicationArea.notificationRequestTabTitle?.[lang],
  notificationChatTabTitle: branding.communicationArea.notificationChatTabTitle?.[lang],
  notificationCallTabTitle: branding.communicationArea.notificationCallTabTitle?.[lang],
  notificationMeetingTabTitle: branding.communicationArea.notificationMeetingTabTitle?.[lang],
  notificationEventTabTitle: branding.communicationArea.notificationEventTabTitle?.[lang],
  messageReceived: branding.communicationArea.messageReceived?.[lang],
  userReportTextTitle: branding.communicationArea.userReportTextTitle?.[lang],
  activateNetworkingText: branding.communicationArea.activateNetworkingText?.[lang],
  notificationEmptyMessage: branding.communicationArea.notificationEmptyMessage?.[lang],
  searchBarTextPerson: branding.communicationArea.searchBarTextPerson?.[lang],
  timezone: branding.communicationArea.timezone?.[lang],
  timezonePickerText: branding.communicationArea.timezonePickerText?.[lang]
}
}

function createchatBranding(lang: Lang) {
	return {
  groupChat: branding.chatBranding.groupChat?.[lang],
  callChatDefaultName: branding.chatBranding.callChatDefaultName?.[lang],
  youText: branding.chatBranding.youText?.[lang],
  detailsText: branding.chatBranding.detailsText?.[lang],
  newChatText: branding.chatBranding.newChatText?.[lang],
  newGroupText: branding.chatBranding.newGroupText?.[lang],
  searchForPeopleText: branding.chatBranding.searchForPeopleText?.[lang],
  participantsText: branding.chatBranding.participantsText?.[lang],
  newGroupChatText: branding.chatBranding.newGroupChatText?.[lang],
  setGroupNameText: branding.chatBranding.setGroupNameText?.[lang],
  addGroupDescriptionText: branding.chatBranding.addGroupDescriptionText?.[lang],
  findParticipantsText: branding.chatBranding.findParticipantsText?.[lang],
  startChatText: branding.chatBranding.startChatText?.[lang],
  addParticipantsText: branding.chatBranding.addParticipantsText?.[lang],
  disableAddedText: branding.chatBranding.disableAddedText?.[lang],
  exitGroupText: branding.chatBranding.exitGroupText?.[lang],
  exitGroupChatText: branding.chatBranding.exitGroupChatText?.[lang],
  cancelText: branding.chatBranding.cancelText?.[lang],
  exitText: branding.chatBranding.exitText?.[lang],
  muteInsteadText: branding.chatBranding.muteInsteadText?.[lang],
  closeText: branding.chatBranding.closeText?.[lang],
  noResultFoundText: branding.chatBranding.noResultFoundText?.[lang],
  titleMeeting: branding.chatBranding.titleMeeting?.[lang],
  meetingCancelled: branding.chatBranding.meetingCancelled?.[lang]
}
}

function createcalendarEntryModalPageContent(lang: Lang) {
	return {
  calendarNotValid: branding.calendarEntryModalPageContent.calendarNotValid?.[lang],
  fieldError: branding.calendarEntryModalPageContent.fieldError?.[lang],
  availabilityTitle: branding.calendarEntryModalPageContent.availabilityTitle?.[lang],
  busyLegendText: branding.calendarEntryModalPageContent.busyLegendText?.[lang],
  availabilityButtonText: branding.calendarEntryModalPageContent.availabilityButtonText?.[lang],
  createTitle: branding.calendarEntryModalPageContent.createTitle?.[lang],
  editTitle: branding.calendarEntryModalPageContent.editTitle?.[lang],
  descriptionTitle: branding.calendarEntryModalPageContent.descriptionTitle?.[lang],
  descriptionTitleAfter: branding.calendarEntryModalPageContent.descriptionTitleAfter?.[lang],
  descriptionTitleOrganization: branding.calendarEntryModalPageContent.descriptionTitleOrganization?.[lang],
  name: branding.calendarEntryModalPageContent.name?.[lang],
  namePlaceholder: branding.calendarEntryModalPageContent.namePlaceholder?.[lang],
  searchPerson: branding.calendarEntryModalPageContent.searchPerson?.[lang],
  searchPersonPlaceholder: branding.calendarEntryModalPageContent.searchPersonPlaceholder?.[lang],
  searchPersonIsInvited: branding.calendarEntryModalPageContent.searchPersonIsInvited?.[lang],
  notFound: branding.calendarEntryModalPageContent.notFound?.[lang],
  startDatePlaceholder: branding.calendarEntryModalPageContent.startDatePlaceholder?.[lang],
  endDatePlaceholder: branding.calendarEntryModalPageContent.endDatePlaceholder?.[lang],
  dateSelectionPlaceholder: branding.calendarEntryModalPageContent.dateSelectionPlaceholder?.[lang],
  day: branding.calendarEntryModalPageContent.day?.[lang],
  start: branding.calendarEntryModalPageContent.start?.[lang],
  end: branding.calendarEntryModalPageContent.end?.[lang],
  dayPlaceholder: branding.calendarEntryModalPageContent.dayPlaceholder?.[lang],
  notes: branding.calendarEntryModalPageContent.notes?.[lang],
  notesPlaceholder: branding.calendarEntryModalPageContent.notesPlaceholder?.[lang],
  sendInvite: branding.calendarEntryModalPageContent.sendInvite?.[lang],
  saveChanges: branding.calendarEntryModalPageContent.saveChanges?.[lang],
  deleteMeeting: branding.calendarEntryModalPageContent.deleteMeeting?.[lang],
  dayFormat: branding.calendarEntryModalPageContent.dayFormat?.[lang],
  calendarEntryParticipationStatusAccepted: branding.calendarEntryModalPageContent.calendarEntryParticipationStatusAccepted?.[lang],
  calendarEntryParticipationStatusDeclined: branding.calendarEntryModalPageContent.calendarEntryParticipationStatusDeclined?.[lang],
  calendarEntryParticipationStatusRequested: branding.calendarEntryModalPageContent.calendarEntryParticipationStatusRequested?.[lang],
  cancel: branding.calendarEntryModalPageContent.cancel?.[lang],
  cancelText: branding.calendarEntryModalPageContent.cancelText?.[lang],
  timeZoneModalTitle: branding.calendarEntryModalPageContent.timeZoneModalTitle?.[lang],
  timeZoneDropdownCurrentTimezoneTitle: branding.calendarEntryModalPageContent.timeZoneDropdownCurrentTimezoneTitle?.[lang],
  timeZone: branding.calendarEntryModalPageContent.timeZone?.[lang],
  available: branding.calendarEntryModalPageContent.available?.[lang],
  busy: branding.calendarEntryModalPageContent.busy?.[lang]
}
}

function createcalendarEntryListView(lang: Lang) {
	return {
  editButtonTitle: branding.calendarEntryListView.editButtonTitle?.[lang],
  viewButtonTitle: branding.calendarEntryListView.viewButtonTitle?.[lang],
  deleteButtonTitle: branding.calendarEntryListView.deleteButtonTitle?.[lang],
  callButtonTitle: branding.calendarEntryListView.callButtonTitle?.[lang],
  chatButtonTitle: branding.calendarEntryListView.chatButtonTitle?.[lang],
  exportButtonTitle: branding.calendarEntryListView.exportButtonTitle?.[lang],
  declineButtonTitle: branding.calendarEntryListView.declineButtonTitle?.[lang],
  acceptButtonTitle: branding.calendarEntryListView.acceptButtonTitle?.[lang],
  acceptTitle: branding.calendarEntryListView.acceptTitle?.[lang],
  declineTitle: branding.calendarEntryListView.declineTitle?.[lang],
  dateTo: branding.calendarEntryListView.dateTo?.[lang],
  dateHourFormat: branding.calendarEntryListView.dateHourFormat?.[lang],
  dateDayFormat: branding.calendarEntryListView.dateDayFormat?.[lang]
}
}

function createcalendarEntryExport(lang: Lang) {
	return {
  organizer: branding.calendarEntryExport.organizer?.[lang],
  attendees: branding.calendarEntryExport.attendees?.[lang]
}
}

function createcontactEntry(lang: Lang) {
	return {
  bookmarkTitle: branding.contactEntry.bookmarkTitle?.[lang],
  bookmarkHint: branding.contactEntry.bookmarkHint?.[lang],
  unbookmarkTitle: branding.contactEntry.unbookmarkTitle?.[lang],
  unbookmarkHint: branding.contactEntry.unbookmarkHint?.[lang],
  sendMessageTitle: branding.contactEntry.sendMessageTitle?.[lang],
  sendMessageHint: branding.contactEntry.sendMessageHint?.[lang],
  startCallTitle: branding.contactEntry.startCallTitle?.[lang],
  startCallHint: branding.contactEntry.startCallHint?.[lang],
  inviteToCallHint: branding.contactEntry.inviteToCallHint?.[lang],
  requestMeetingTitle: branding.contactEntry.requestMeetingTitle?.[lang],
  requestMeetingHint: branding.contactEntry.requestMeetingHint?.[lang],
  connectTitle: branding.contactEntry.connectTitle?.[lang],
  connectHint: branding.contactEntry.connectHint?.[lang],
  disconnectTitle: branding.contactEntry.disconnectTitle?.[lang],
  disconnectHint: branding.contactEntry.disconnectHint?.[lang],
  shareTitle: branding.contactEntry.shareTitle?.[lang],
  shareHint: branding.contactEntry.shareHint?.[lang],
  blockPersonTitle: branding.contactEntry.blockPersonTitle?.[lang],
  blockPersonHint: branding.contactEntry.blockPersonHint?.[lang],
  unblockPersonTitle: branding.contactEntry.unblockPersonTitle?.[lang],
  unblockPersonHint: branding.contactEntry.unblockPersonHint?.[lang],
  downloadVCardTitle: branding.contactEntry.downloadVCardTitle?.[lang],
  downloadVCardHint: branding.contactEntry.downloadVCardHint?.[lang],
  cancelRequestTitle: branding.contactEntry.cancelRequestTitle?.[lang],
  cancelRequestMessage: branding.contactEntry.cancelRequestMessage?.[lang],
  cancelConnectionMessage: branding.contactEntry.cancelConnectionMessage?.[lang],
  ignoredMessage: branding.contactEntry.ignoredMessage?.[lang],
  openRequestMessage: branding.contactEntry.openRequestMessage?.[lang],
  sendRequestMessage: branding.contactEntry.sendRequestMessage?.[lang],
  expoUserBadgeTitle: branding.contactEntry.expoUserBadgeTitle?.[lang],
  speakerUserBadgeTitle: branding.contactEntry.speakerUserBadgeTitle?.[lang],
  visitorUserBadgeTitle: branding.contactEntry.visitorUserBadgeTitle?.[lang],
  addStaffText: branding.contactEntry.addStaffText?.[lang],
  removeStaffText: branding.contactEntry.removeStaffText?.[lang],
  openRequestMessageHint: branding.contactEntry.openRequestMessageHint?.[lang]
}
}

function createsideIconBar(lang: Lang) {
	return {
  searchIconText: branding.sideIconBar.searchIconText?.[lang],
  homeIconText: branding.sideIconBar.homeIconText?.[lang],
  conferenceIconText: branding.sideIconBar.conferenceIconText?.[lang],
  seminarsIconText: branding.sideIconBar.seminarsIconText?.[lang],
  chatroomsIconText: branding.sideIconBar.chatroomsIconText?.[lang],
  networkIconText: branding.sideIconBar.networkIconText?.[lang],
  expoIconText: branding.sideIconBar.expoIconText?.[lang],
  bookmarksIconText: branding.sideIconBar.bookmarksIconText?.[lang],
  bookmarksTooltip: branding.sideIconBar.bookmarksTooltip?.[lang],
  historyIconText: branding.sideIconBar.historyIconText?.[lang],
  licensingIconText: branding.sideIconBar.licensingIconText?.[lang],
  profileIconText: branding.sideIconBar.profileIconText?.[lang],
  searchBarPlaceholder: branding.sideIconBar.searchBarPlaceholder?.[lang],
  lobbyMenuText: branding.sideIconBar.lobbyMenuText?.[lang],
  liveMenuText: branding.sideIconBar.liveMenuText?.[lang],
  masterclassesMenuText: branding.sideIconBar.masterclassesMenuText?.[lang],
  programMenuText: branding.sideIconBar.programMenuText?.[lang],
  virtualcafeMenuText: branding.sideIconBar.virtualcafeMenuText?.[lang],
  networkingMenuText: branding.sideIconBar.networkingMenuText?.[lang],
  showfloorMenuText: branding.sideIconBar.showfloorMenuText?.[lang],
  mediapartnersMenuText: branding.sideIconBar.mediapartnersMenuText?.[lang],
  pressMediaMenuText: branding.sideIconBar.pressMediaMenuText?.[lang],
  businessAreaMenuText: branding.sideIconBar.businessAreaMenuText?.[lang],
  experiencesMenuText: branding.sideIconBar.experiencesMenuText?.[lang],
  goodiebagMenuText: branding.sideIconBar.goodiebagMenuText?.[lang],
  companiesSearchDrawerText: branding.sideIconBar.companiesSearchDrawerText?.[lang],
  peopleSearchDrawerText: branding.sideIconBar.peopleSearchDrawerText?.[lang],
  networkingParticipantsSearchDrawerText: branding.sideIconBar.networkingParticipantsSearchDrawerText?.[lang],
  programSearchDrawerText: branding.sideIconBar.programSearchDrawerText?.[lang],
  productsSearchDrawerText: branding.sideIconBar.productsSearchDrawerText?.[lang],
  trademarksSearchDrawerText: branding.sideIconBar.trademarksSearchDrawerText?.[lang],
  newsSearchDrawerText: branding.sideIconBar.newsSearchDrawerText?.[lang],
  viewAllCompaniesSearchDrawerText: branding.sideIconBar.viewAllCompaniesSearchDrawerText?.[lang],
  viewAllPeopleSearchDrawerText: branding.sideIconBar.viewAllPeopleSearchDrawerText?.[lang],
  viewAllProgramSearchDrawerText: branding.sideIconBar.viewAllProgramSearchDrawerText?.[lang],
  viewAllProductsSearchDrawerText: branding.sideIconBar.viewAllProductsSearchDrawerText?.[lang],
  viewAllTrademarksSearchDrawerText: branding.sideIconBar.viewAllTrademarksSearchDrawerText?.[lang],
  viewAllNewsSearchDrawerText: branding.sideIconBar.viewAllNewsSearchDrawerText?.[lang],
  viewAllCouponsSearchDrawerText: branding.sideIconBar.viewAllCouponsSearchDrawerText?.[lang],
  emptyResultMessage: branding.sideIconBar.emptyResultMessage?.[lang],
  topSponsorsText: branding.sideIconBar.topSponsorsText?.[lang],
  ideaSponsorsText: branding.sideIconBar.ideaSponsorsText?.[lang],
  helpItem: {
    title: branding.sideIconBar.helpItem.title?.[lang],
    link: branding.sideIconBar.helpItem.link?.[lang]
  },
  rulesItem: {
    title: branding.sideIconBar.rulesItem.title?.[lang],
    link: branding.sideIconBar.rulesItem.link?.[lang]
  },
  termsOfUseItem: {
    title: branding.sideIconBar.termsOfUseItem.title?.[lang],
    link: branding.sideIconBar.termsOfUseItem.link?.[lang]
  },
  dataPrivacyPolicyItem: {
    title: branding.sideIconBar.dataPrivacyPolicyItem.title?.[lang],
    link: branding.sideIconBar.dataPrivacyPolicyItem.link?.[lang]
  },
  imprintItem: {
    title: branding.sideIconBar.imprintItem.title?.[lang],
    link: branding.sideIconBar.imprintItem.link?.[lang]
  }
}
}

function createbackendError(lang: Lang) {
	return {
  errorMessage: branding.backendError.errorMessage?.[lang],
  errorButtonTitle: branding.backendError.errorButtonTitle?.[lang]
}
}

function createnavigationArea(lang: Lang) {
	return {
  speakersItemTitle: branding.navigationArea.speakersItemTitle?.[lang],
  productsItemTitle: branding.navigationArea.productsItemTitle?.[lang],
  trademarksItemTitle: branding.navigationArea.trademarksItemTitle?.[lang],
  newsItemTitle: branding.navigationArea.newsItemTitle?.[lang],
  sponsorsItemTitle: branding.navigationArea.sponsorsItemTitle?.[lang],
  sessionsItemTitle: branding.navigationArea.sessionsItemTitle?.[lang],
  liveSessionsItemTitle: branding.navigationArea.liveSessionsItemTitle?.[lang],
  videoOnDemandItemTitle: branding.navigationArea.videoOnDemandItemTitle?.[lang],
  futureCafeItemTitle: branding.navigationArea.futureCafeItemTitle?.[lang],
  businessCafeItemTitle: branding.navigationArea.businessCafeItemTitle?.[lang],
  marketingCafeItemTitle: branding.navigationArea.marketingCafeItemTitle?.[lang],
  technologyCafeItemTitle: branding.navigationArea.technologyCafeItemTitle?.[lang],
  networkingItemTitle: branding.navigationArea.networkingItemTitle?.[lang],
  participantsItemTitle: branding.navigationArea.participantsItemTitle?.[lang],
  companiesItemTitle: branding.navigationArea.companiesItemTitle?.[lang],
  peopleItemTitle: branding.navigationArea.peopleItemTitle?.[lang],
  eventdateItemTitle: branding.navigationArea.eventdateItemTitle?.[lang],
  scheduleItemTitle: branding.navigationArea.scheduleItemTitle?.[lang],
  presenceItemTitle: branding.navigationArea.presenceItemTitle?.[lang],
  themesItemTitle: branding.navigationArea.themesItemTitle?.[lang],
  themeItemTitle: branding.navigationArea.themeItemTitle?.[lang],
  presenceTypes: {
    availableState: branding.navigationArea.presenceTypes.availableState?.[lang],
    busyState: branding.navigationArea.presenceTypes.busyState?.[lang],
    doNotDisturbState: branding.navigationArea.presenceTypes.doNotDisturbState?.[lang],
    offWork: branding.navigationArea.presenceTypes.offWork?.[lang]
  },
  signOutTitle: branding.navigationArea.signOutTitle?.[lang]
}
}

function createmediaPartnerPageContent(lang: Lang) {
	return {
  mediaPartnerName: branding.mediaPartnerPageContent.mediaPartnerName?.[lang]
}
}

function createsponsorsPageContent(lang: Lang) {
	return {
  sponsorsHeader: branding.sponsorsPageContent.sponsorsHeader?.[lang],
  moreSponsorsInfo: branding.sponsorsPageContent.moreSponsorsInfo?.[lang]
}
}

function createmyProfilePageContent(lang: Lang) {
	return {
  myProfileTitle: branding.myProfilePageContent.myProfileTitle?.[lang],
  editProfileButtonText: branding.myProfilePageContent.editProfileButtonText?.[lang],
  noInterestsSelectedMessage: branding.myProfilePageContent.noInterestsSelectedMessage?.[lang],
  noOffersSelectedMessage: branding.myProfilePageContent.noOffersSelectedMessage?.[lang],
  noNeedsSelectedMessage: branding.myProfilePageContent.noNeedsSelectedMessage?.[lang],
  goBackLabel: branding.myProfilePageContent.goBackLabel?.[lang],
  profilePictureLabel: branding.myProfilePageContent.profilePictureLabel?.[lang],
  uploadNewButtonText: branding.myProfilePageContent.uploadNewButtonText?.[lang],
  formatsText: branding.myProfilePageContent.formatsText?.[lang],
  deleteCurrentPictureButtonText: branding.myProfilePageContent.deleteCurrentPictureButtonText?.[lang],
  nameLabel: branding.myProfilePageContent.nameLabel?.[lang],
  surnameLabel: branding.myProfilePageContent.surnameLabel?.[lang],
  companyLabel: branding.myProfilePageContent.companyLabel?.[lang],
  jobTitleLabel: branding.myProfilePageContent.jobTitleLabel?.[lang],
  phoneLabel: branding.myProfilePageContent.phoneLabel?.[lang],
  mobileLabel: branding.myProfilePageContent.mobileLabel?.[lang],
  timeZoneLabel: branding.myProfilePageContent.timeZoneLabel?.[lang],
  industryLabel: branding.myProfilePageContent.industryLabel?.[lang],
  countryLabel: branding.myProfilePageContent.countryLabel?.[lang],
  countrySwitcherText: branding.myProfilePageContent.countrySwitcherText?.[lang],
  languagesLabel: branding.myProfilePageContent.languagesLabel?.[lang],
  languagesSwitcherText: branding.myProfilePageContent.languagesSwitcherText?.[lang],
  facebookLinkLabel: branding.myProfilePageContent.facebookLinkLabel?.[lang],
  twitterLinkLabel: branding.myProfilePageContent.twitterLinkLabel?.[lang],
  linkedInLinkLabel: branding.myProfilePageContent.linkedInLinkLabel?.[lang],
  xingLinkLabel: branding.myProfilePageContent.xingLinkLabel?.[lang],
  instagramLinkLabel: branding.myProfilePageContent.instagramLinkLabel?.[lang],
  youTubeLinkLabel: branding.myProfilePageContent.youTubeLinkLabel?.[lang],
  pinterestLinkLabel: branding.myProfilePageContent.pinterestLinkLabel?.[lang],
  biographyLabel: branding.myProfilePageContent.biographyLabel?.[lang],
  interestsLabel: branding.myProfilePageContent.interestsLabel?.[lang],
  imOfferingLabel: branding.myProfilePageContent.imOfferingLabel?.[lang],
  offersNeedsCategories: createMyProfilePageContentOffersNeedsCategories(branding.myProfilePageContent.offersNeedsCategories, lang),
  imLookingToBuyLabel: branding.myProfilePageContent.imLookingToBuyLabel?.[lang],
  showMoreButtonText: branding.myProfilePageContent.showMoreButtonText?.[lang],
  showLessButtonText: branding.myProfilePageContent.showLessButtonText?.[lang],
  requiredFieldsLabel: branding.myProfilePageContent.requiredFieldsLabel?.[lang],
  saveAndUpdateButtonText: branding.myProfilePageContent.saveAndUpdateButtonText?.[lang],
  discardChangesButtonText: branding.myProfilePageContent.discardChangesButtonText?.[lang],
  oneRequiredFieldEmptyWarning: branding.myProfilePageContent.oneRequiredFieldEmptyWarning?.[lang],
  requiredFieldsEmptyWarning: branding.myProfilePageContent.requiredFieldsEmptyWarning?.[lang],
  validationIncorrectWarning: branding.myProfilePageContent.validationIncorrectWarning?.[lang],
  invalidNameWarning: branding.myProfilePageContent.invalidNameWarning?.[lang],
  invalidSurnameWarning: branding.myProfilePageContent.invalidSurnameWarning?.[lang],
  invalidCompanyNameWarning: branding.myProfilePageContent.invalidCompanyNameWarning?.[lang],
  invalidIndustryNameWarning: branding.myProfilePageContent.invalidIndustryNameWarning?.[lang],
  invalidJobTitleWarning: branding.myProfilePageContent.invalidJobTitleWarning?.[lang],
  invalidPhoneNumberWarning: branding.myProfilePageContent.invalidPhoneNumberWarning?.[lang],
  invalidFacebookLinkWarning: branding.myProfilePageContent.invalidFacebookLinkWarning?.[lang],
  invalidTwitterLinkWarning: branding.myProfilePageContent.invalidTwitterLinkWarning?.[lang],
  invalidLinkedInLinkWarning: branding.myProfilePageContent.invalidLinkedInLinkWarning?.[lang],
  invalidXingLinkWarning: branding.myProfilePageContent.invalidXingLinkWarning?.[lang],
  invalidInstagramLinkWarning: branding.myProfilePageContent.invalidInstagramLinkWarning?.[lang],
  invalidYouTubeLinkWarning: branding.myProfilePageContent.invalidYouTubeLinkWarning?.[lang],
  invalidPinterestLinkWarning: branding.myProfilePageContent.invalidPinterestLinkWarning?.[lang],
  pictureSizeWarning: branding.myProfilePageContent.pictureSizeWarning?.[lang],
  backendErrorWarning: branding.myProfilePageContent.backendErrorWarning?.[lang],
  dataSuccessfullyUpdatedText: branding.myProfilePageContent.dataSuccessfullyUpdatedText?.[lang],
  searchPlaceholder: branding.myProfilePageContent.searchPlaceholder?.[lang]
}
}

function createpersonDetailPageContent(lang: Lang) {
	return {
  navbarText: branding.personDetailPageContent.navbarText?.[lang],
  cancelRequestMessage: branding.personDetailPageContent.cancelRequestMessage?.[lang],
  cancelConnectionMessage: branding.personDetailPageContent.cancelConnectionMessage?.[lang],
  ignoredMessage: branding.personDetailPageContent.ignoredMessage?.[lang],
  openRequestMessage: branding.personDetailPageContent.openRequestMessage?.[lang],
  sendRequestMessage: branding.personDetailPageContent.sendRequestMessage?.[lang],
  chatText: branding.personDetailPageContent.chatText?.[lang],
  videoCallText: branding.personDetailPageContent.videoCallText?.[lang],
  voiceCallText: branding.personDetailPageContent.voiceCallText?.[lang],
  voiceCallingText: branding.personDetailPageContent.voiceCallingText?.[lang],
  meetingText: branding.personDetailPageContent.meetingText?.[lang],
  liveSessionsTitle: branding.personDetailPageContent.liveSessionsTitle?.[lang],
  upcomingSessionsTitle: branding.personDetailPageContent.upcomingSessionsTitle?.[lang],
  interestsTitle: branding.personDetailPageContent.interestsTitle?.[lang],
  offersTitle: branding.personDetailPageContent.offersTitle?.[lang],
  needsTitle: branding.personDetailPageContent.needsTitle?.[lang],
  noInterestsSelectedMessage: branding.personDetailPageContent.noInterestsSelectedMessage?.[lang]
}
}

function createsayHelloModal(lang: Lang) {
	return {
  cancelText: branding.sayHelloModal.cancelText?.[lang],
  cancelRequestMessage: branding.sayHelloModal.cancelRequestMessage?.[lang],
  cancelConnectionMessage: branding.sayHelloModal.cancelConnectionMessage?.[lang],
  cancelConnectionTitle: branding.sayHelloModal.cancelConnectionTitle?.[lang],
  ignoredMessage: branding.sayHelloModal.ignoredMessage?.[lang],
  openRequestMessage: branding.sayHelloModal.openRequestMessage?.[lang],
  openRequestMessageModal: branding.sayHelloModal.openRequestMessageModal?.[lang],
  sendMessagePlaceholder: branding.sayHelloModal.sendMessagePlaceholder?.[lang],
  headerTextSayHello: branding.sayHelloModal.headerTextSayHello?.[lang],
  headerTextCancelRequest: branding.sayHelloModal.headerTextCancelRequest?.[lang],
  headerTextOrganizationRequest: branding.sayHelloModal.headerTextOrganizationRequest?.[lang],
  submitTextAccept: branding.sayHelloModal.submitTextAccept?.[lang],
  submitTextIgnore: branding.sayHelloModal.submitTextIgnore?.[lang],
  submitTextYes: branding.sayHelloModal.submitTextYes?.[lang],
  cancelTextYes: branding.sayHelloModal.cancelTextYes?.[lang],
  submitTextForCancelButton: branding.sayHelloModal.submitTextForCancelButton?.[lang],
  cancelTextForCancelButton: branding.sayHelloModal.cancelTextForCancelButton?.[lang],
  submitTextSend: branding.sayHelloModal.submitTextSend?.[lang],
  submitTextClose: branding.sayHelloModal.submitTextClose?.[lang],
  subtitleRegisterInterestsPart1: branding.sayHelloModal.subtitleRegisterInterestsPart1?.[lang],
  subtitleRegisterInterestsPart2: branding.sayHelloModal.subtitleRegisterInterestsPart2?.[lang],
  subtitleRegisterInterestsPart3: branding.sayHelloModal.subtitleRegisterInterestsPart3?.[lang],
  subtitleConnectRequestPart1: branding.sayHelloModal.subtitleConnectRequestPart1?.[lang],
  subtitleConnectRequestPart2: branding.sayHelloModal.subtitleConnectRequestPart2?.[lang],
  alertMessage: branding.sayHelloModal.alertMessage?.[lang]
}
}

function createrecommendModal(lang: Lang) {
	return {
  title: branding.recommendModal.title?.[lang],
  titleContact: branding.recommendModal.titleContact?.[lang],
  subtitlePart1: branding.recommendModal.subtitlePart1?.[lang],
  subtitlePart2: branding.recommendModal.subtitlePart2?.[lang],
  shareButton: branding.recommendModal.shareButton?.[lang],
  sendMessageOrganization: branding.recommendModal.sendMessageOrganization?.[lang],
  sendMessagePerson: branding.recommendModal.sendMessagePerson?.[lang],
  sendMessageSotUser: branding.recommendModal.sendMessageSotUser?.[lang],
  sendMessageEventdate: branding.recommendModal.sendMessageEventdate?.[lang],
  notes: branding.recommendModal.notes?.[lang],
  notesPlaceholder: branding.recommendModal.notesPlaceholder?.[lang]
}
}

function createfilterBar(lang: Lang) {
	return {
  allFilterTitle: branding.filterBar.allFilterTitle?.[lang]
}
}

function createorganizationDetailPageContent(lang: Lang) {
	return {
  navbarTitle: branding.organizationDetailPageContent.navbarTitle?.[lang],
  sectionLineContactPerson: branding.organizationDetailPageContent.sectionLineContactPerson?.[lang],
  sectionLineCategories: branding.organizationDetailPageContent.sectionLineCategories?.[lang],
  sectionLineProductDescription: branding.organizationDetailPageContent.sectionLineProductDescription?.[lang],
  buttonShowMore: branding.organizationDetailPageContent.buttonShowMore?.[lang],
  buttonCollapse: branding.organizationDetailPageContent.buttonCollapse?.[lang],
  joinShowroom: branding.organizationDetailPageContent.joinShowroom?.[lang],
  requestMeeting: branding.organizationDetailPageContent.requestMeeting?.[lang],
  registerInterest: branding.organizationDetailPageContent.registerInterest?.[lang],
  recommend: branding.organizationDetailPageContent.recommend?.[lang],
  bookmark: branding.organizationDetailPageContent.bookmark?.[lang],
  backoffice: branding.organizationDetailPageContent.backoffice?.[lang],
  connectWithUs: branding.organizationDetailPageContent.connectWithUs?.[lang],
  talkWithTeamBefString: branding.organizationDetailPageContent.talkWithTeamBefString?.[lang],
  talkWithTeamAftString: branding.organizationDetailPageContent.talkWithTeamAftString?.[lang],
  joinButton: branding.organizationDetailPageContent.joinButton?.[lang],
  liveSessionsTitle: branding.organizationDetailPageContent.liveSessionsTitle?.[lang],
  upcomingSessionsTitle: branding.organizationDetailPageContent.upcomingSessionsTitle?.[lang],
  mediaTitle: branding.organizationDetailPageContent.mediaTitle?.[lang],
  virtualCafesTitle: branding.organizationDetailPageContent.virtualCafesTitle?.[lang],
  linksTitle: branding.organizationDetailPageContent.linksTitle?.[lang],
  joinNowButton: branding.organizationDetailPageContent.joinNowButton?.[lang],
  showFullScreen: branding.organizationDetailPageContent.showFullScreen?.[lang],
  bookmarkPerson: branding.organizationDetailPageContent.bookmarkPerson?.[lang],
  foundingYearTitle: branding.organizationDetailPageContent.foundingYearTitle?.[lang],
  companySizeTitle: branding.organizationDetailPageContent.companySizeTitle?.[lang],
  industryTitle: branding.organizationDetailPageContent.industryTitle?.[lang],
  explorationPhasePlaceholder: branding.organizationDetailPageContent.explorationPhasePlaceholder?.[lang],
  pastSessionsTitle: branding.organizationDetailPageContent.pastSessionsTitle?.[lang],
  postPhasePlaceholder: branding.organizationDetailPageContent.postPhasePlaceholder?.[lang],
  companyInformationTitle: branding.organizationDetailPageContent.companyInformationTitle?.[lang],
  companyContactToTitle: branding.organizationDetailPageContent.companyContactToTitle?.[lang],
  categoriesSectionTitle: branding.organizationDetailPageContent.categoriesSectionTitle?.[lang],
  coExhibitorsSectionTitle: branding.organizationDetailPageContent.coExhibitorsSectionTitle?.[lang],
  parentSectionTitle: branding.organizationDetailPageContent.parentSectionTitle?.[lang],
  vCardNoteTemplate: branding.organizationDetailPageContent.vCardNoteTemplate?.[lang]
}
}

function createbackofficeContent(lang: Lang) {
	return {
  navbarTitle: branding.backofficeContent.navbarTitle?.[lang],
  staffSectiontitle: branding.backofficeContent.staffSectiontitle?.[lang],
  guestsSectionTitle: branding.backofficeContent.guestsSectionTitle?.[lang],
  contactRequestsSectionTitle: branding.backofficeContent.contactRequestsSectionTitle?.[lang],
  meetingsSectionTitle: branding.backofficeContent.meetingsSectionTitle?.[lang],
  loungeSectionTitle: branding.backofficeContent.loungeSectionTitle?.[lang],
  reportingSectionTitle: branding.backofficeContent.reportingSectionTitle?.[lang],
  requestMessageTitle: branding.backofficeContent.requestMessageTitle?.[lang],
  noRequestMessageDefault: branding.backofficeContent.noRequestMessageDefault?.[lang],
  noActiveContactRequests: branding.backofficeContent.noActiveContactRequests?.[lang],
  ignoreContactRequstTitle: branding.backofficeContent.ignoreContactRequstTitle?.[lang],
  ignoreContactRequstText: branding.backofficeContent.ignoreContactRequstText?.[lang],
  ignoreContactText: branding.backofficeContent.ignoreContactText?.[lang],
  emptySearch: branding.backofficeContent.emptySearch?.[lang],
  helpLink: branding.backofficeContent.helpLink?.[lang],
  helpLinkText1: branding.backofficeContent.helpLinkText1?.[lang],
  helpLinkText2: branding.backofficeContent.helpLinkText2?.[lang],
  alreadyMemberTitle: branding.backofficeContent.alreadyMemberTitle?.[lang]
}
}

function createbackofficeMeetings(lang: Lang) {
	return {
  alreadyProcessedText: branding.backofficeMeetings.alreadyProcessedText?.[lang]
}
}

function createbackofficeStaff(lang: Lang) {
	return {
  addStaffButton: branding.backofficeStaff.addStaffButton?.[lang],
  searchPersonPlaceholder: branding.backofficeStaff.searchPersonPlaceholder?.[lang],
  removeStaffConfirmationTitle: branding.backofficeStaff.removeStaffConfirmationTitle?.[lang],
  removeStaffConfirmationText: branding.backofficeStaff.removeStaffConfirmationText?.[lang],
  cancelButton: branding.backofficeStaff.cancelButton?.[lang],
  removeStaffButton: branding.backofficeStaff.removeStaffButton?.[lang],
  alreadyAddedTitle: branding.backofficeStaff.alreadyAddedTitle?.[lang],
  alreadyAddedText: branding.backofficeStaff.alreadyAddedText?.[lang],
  showMeAsContactHoverText: branding.backofficeStaff.showMeAsContactHoverText?.[lang],
  showMeAsContactDeactivateHoverText: branding.backofficeStaff.showMeAsContactDeactivateHoverText?.[lang],
  showShoowroomStandbyHoverText: branding.backofficeStaff.showShoowroomStandbyHoverText?.[lang],
  showShoowroomStandbyDeactivateHoverText: branding.backofficeStaff.showShoowroomStandbyDeactivateHoverText?.[lang],
  startCall: branding.backofficeStaff.startCall?.[lang],
  removeStaff: branding.backofficeStaff.removeStaff?.[lang],
  startChat: branding.backofficeStaff.startChat?.[lang],
  requestMeeting: branding.backofficeStaff.requestMeeting?.[lang],
  share: branding.backofficeStaff.share?.[lang],
  bookmark: branding.backofficeStaff.bookmark?.[lang],
  myBadgeText: branding.backofficeStaff.myBadgeText?.[lang],
  addStaffButtonDescription: branding.backofficeStaff.addStaffButtonDescription?.[lang],
  alreadyMemberTitle: branding.backofficeStaff.alreadyMemberTitle?.[lang]
}
}

function createbackofficeVisitors(lang: Lang) {
	return {
  guestsSelect: branding.backofficeVisitors.guestsSelect?.[lang],
  visitorsSelect: branding.backofficeVisitors.visitorsSelect?.[lang],
  contactsSelct: branding.backofficeVisitors.contactsSelct?.[lang],
  leadsSelect: branding.backofficeVisitors.leadsSelect?.[lang],
  dbSelect: branding.backofficeVisitors.dbSelect?.[lang],
  noGuests: branding.backofficeVisitors.noGuests?.[lang],
  visitorsFilter: branding.backofficeVisitors.visitorsFilter?.[lang],
  refreshList: branding.backofficeVisitors.refreshList?.[lang],
  downloadAsCSV: branding.backofficeVisitors.downloadAsCSV?.[lang],
  optInLabel: branding.backofficeVisitors.optInLabel?.[lang],
  showProducts: branding.backofficeVisitors.showProducts?.[lang],
  showTrademarks: branding.backofficeVisitors.showTrademarks?.[lang]
}
}

function createbackofficeLounge(lang: Lang) {
	return {
  noMeetingGroups: branding.backofficeLounge.noMeetingGroups?.[lang],
  noRequest: branding.backofficeLounge.noRequest?.[lang],
  noGranted: branding.backofficeLounge.noGranted?.[lang],
  requestReasonTitle: branding.backofficeLounge.requestReasonTitle?.[lang],
  acceptAccessTitle: branding.backofficeLounge.acceptAccessTitle?.[lang],
  declineAccessTitle: branding.backofficeLounge.declineAccessTitle?.[lang]
}
}

function createbackofficeReporting(lang: Lang) {
	return {
  showroomSelect: branding.backofficeReporting.showroomSelect?.[lang],
  expoSelect: branding.backofficeReporting.expoSelect?.[lang],
  meetingsSelect: branding.backofficeReporting.meetingsSelect?.[lang],
  interestsSelect: branding.backofficeReporting.interestsSelect?.[lang],
  recommendationSelect: branding.backofficeReporting.recommendationSelect?.[lang],
  vcSelect: branding.backofficeReporting.vcSelect?.[lang],
  vcRoomSelect: branding.backofficeReporting.vcRoomSelect?.[lang],
  linksSelect: branding.backofficeReporting.linksSelect?.[lang],
  mediaSelect: branding.backofficeReporting.mediaSelect?.[lang],
  liveSelect: branding.backofficeReporting.liveSelect?.[lang],
  eventDateSelect: branding.backofficeReporting.eventDateSelect?.[lang],
  sourceFloor: branding.backofficeReporting.sourceFloor?.[lang],
  sourceLobby: branding.backofficeReporting.sourceLobby?.[lang],
  sourceVC: branding.backofficeReporting.sourceVC?.[lang],
  sourceSidebar: branding.backofficeReporting.sourceSidebar?.[lang],
  sourceCoupon: branding.backofficeReporting.sourceCoupon?.[lang],
  sourceMagazine: branding.backofficeReporting.sourceMagazine?.[lang],
  sourcePress: branding.backofficeReporting.sourcePress?.[lang],
  sourceBookmarks: branding.backofficeReporting.sourceBookmarks?.[lang],
  sourceSearch: branding.backofficeReporting.sourceSearch?.[lang],
  sourceBanner: branding.backofficeReporting.sourceBanner?.[lang],
  sourceSponsors: branding.backofficeReporting.sourceSponsors?.[lang],
  sourceNotification: branding.backofficeReporting.sourceNotification?.[lang],
  sourceOrganization: branding.backofficeReporting.sourceOrganization?.[lang],
  sourceEventDate: branding.backofficeReporting.sourceEventDate?.[lang],
  sourceProgram: branding.backofficeReporting.sourceProgram?.[lang],
  sourcePerson: branding.backofficeReporting.sourcePerson?.[lang],
  total: branding.backofficeReporting.total?.[lang],
  visits: branding.backofficeReporting.visits?.[lang],
  clicks: branding.backofficeReporting.clicks?.[lang],
  requests: branding.backofficeReporting.requests?.[lang],
  sent: branding.backofficeReporting.sent?.[lang],
  preview: branding.backofficeReporting.preview?.[lang],
  download: branding.backofficeReporting.download?.[lang],
  hour: branding.backofficeReporting.hour?.[lang],
  hours: branding.backofficeReporting.hours?.[lang],
  misc: branding.backofficeReporting.misc?.[lang]
}
}

function createproductDetailPageContent(lang: Lang) {
	return {
  navbarText: branding.productDetailPageContent.navbarText?.[lang]
}
}

function createtrademarkDetailPageContent(lang: Lang) {
	return {
  navbarText: branding.trademarkDetailPageContent.navbarText?.[lang]
}
}

function createeventDateDetailPageContent(lang: Lang) {
	return {
  personsTitle: branding.eventDateDetailPageContent.personsTitle?.[lang],
  galleryTitle: branding.eventDateDetailPageContent.galleryTitle?.[lang],
  noPersonsMessage: branding.eventDateDetailPageContent.noPersonsMessage?.[lang],
  noSectionTitle: branding.eventDateDetailPageContent.noSectionTitle?.[lang],
  eventDateRoundTableNoAccessText: branding.eventDateDetailPageContent.eventDateRoundTableNoAccessText?.[lang],
  eventDateRoundTableGrantedAccessText: branding.eventDateDetailPageContent.eventDateRoundTableGrantedAccessText?.[lang],
  eventDateRoundTablePostEventText: branding.eventDateDetailPageContent.eventDateRoundTablePostEventText?.[lang],
  eventDateRoundTableButtonAccessGrantedLive: branding.eventDateDetailPageContent.eventDateRoundTableButtonAccessGrantedLive?.[lang],
  eventDateRoundTableButtonPostEvent: branding.eventDateDetailPageContent.eventDateRoundTableButtonPostEvent?.[lang],
  eventDateRoundTableButtonCountdown: {
    singular: branding.eventDateDetailPageContent.eventDateRoundTableButtonCountdown.singular?.[lang],
    plural: branding.eventDateDetailPageContent.eventDateRoundTableButtonCountdown.plural?.[lang]
  }
}
}

function createproductsPageContent(lang: Lang) {
	return {
  navigationSearchPlaceholderProducts: branding.productsPageContent.navigationSearchPlaceholderProducts?.[lang],
  loadingMessage: branding.productsPageContent.loadingMessage?.[lang],
  noBookmarksMessage: branding.productsPageContent.noBookmarksMessage?.[lang]
}
}

function createtrademarksPageContent(lang: Lang) {
	return {
  navigationSearchPlaceholderTrademarks: branding.trademarksPageContent.navigationSearchPlaceholderTrademarks?.[lang],
  loadingMessage: branding.trademarksPageContent.loadingMessage?.[lang],
  noBookmarksMessage: branding.trademarksPageContent.noBookmarksMessage?.[lang]
}
}

function createnewsPageContent(lang: Lang) {
	return {
  newsListTitle: branding.newsPageContent.newsListTitle?.[lang],
  newsListSearchPlaceholder: branding.newsPageContent.newsListSearchPlaceholder?.[lang],
  noSearchResultsLabel: branding.newsPageContent.noSearchResultsLabel?.[lang],
  noBookmarksMessage: branding.newsPageContent.noBookmarksMessage?.[lang]
}
}

function createexhibitorsPageContent(lang: Lang) {
	return {
  navigationSearchPlaceholderCompanies: branding.exhibitorsPageContent.navigationSearchPlaceholderCompanies?.[lang],
  loadingMessage: branding.exhibitorsPageContent.loadingMessage?.[lang],
  noBookmarksMessage: branding.exhibitorsPageContent.noBookmarksMessage?.[lang],
  onlyShowBookmarksTitle: branding.exhibitorsPageContent.onlyShowBookmarksTitle?.[lang],
  cardView: branding.exhibitorsPageContent.cardView?.[lang],
  listView: branding.exhibitorsPageContent.listView?.[lang],
  suggestGroups: createExhibitorsPageContentSuggestGroups(branding.exhibitorsPageContent.suggestGroups, lang),
  sectionHeaderTop: branding.exhibitorsPageContent.sectionHeaderTop?.[lang],
  sectionHeaderAllExhibitors: branding.exhibitorsPageContent.sectionHeaderAllExhibitors?.[lang],
  sectionHeaderAllProducts: branding.exhibitorsPageContent.sectionHeaderAllProducts?.[lang],
  sectionHeaderAllTrademarks: branding.exhibitorsPageContent.sectionHeaderAllTrademarks?.[lang],
  fillersData: {
    standard: createExhibitorsPageContentFillersDataStandard(branding.exhibitorsPageContent.fillersData.standard, lang),
    basic: createExhibitorsPageContentFillersDataBasic(branding.exhibitorsPageContent.fillersData.basic, lang),
    none: createExhibitorsPageContentFillersDataNone(branding.exhibitorsPageContent.fillersData.none, lang)
  },
  bookmarkedExhibitorsTitle: branding.exhibitorsPageContent.bookmarkedExhibitorsTitle?.[lang],
  firstFilterTitle: branding.exhibitorsPageContent.firstFilterTitle?.[lang],
  secondFilterTitle: branding.exhibitorsPageContent.secondFilterTitle?.[lang],
  firstFilterCategories: createExhibitorsPageContentFirstFilterCategories(branding.exhibitorsPageContent.firstFilterCategories, lang),
  secondFilterCategories: createExhibitorsPageContentSecondFilterCategories(branding.exhibitorsPageContent.secondFilterCategories, lang)
}
}

function createinfoPage(lang: Lang) {
	return {
  contactInfo1: {
    addressNumber: branding.infoPage.contactInfo1.addressNumber?.[lang]
  },
  management1: {
    title: branding.infoPage.management1.title?.[lang],
    manager1: branding.infoPage.management1.manager1?.[lang]
  },
  contactInfo2: {
    addressNumber: branding.infoPage.contactInfo2.addressNumber?.[lang]
  },
  management2: {
    title: branding.infoPage.management2.title?.[lang]
  },
  contactInfo3: {
    addressNumber: branding.infoPage.contactInfo3.addressNumber?.[lang]
  },
  management3: {
    title: branding.infoPage.management3.title?.[lang]
  },
  persons: {
    title: branding.infoPage.persons.title?.[lang],
    item1: branding.infoPage.persons.item1?.[lang],
    item2: branding.infoPage.persons.item2?.[lang]
  },
  headquarters: {
    title: branding.infoPage.headquarters.title?.[lang],
    item2: branding.infoPage.headquarters.item2?.[lang],
    item4: branding.infoPage.headquarters.item4?.[lang],
    item6: branding.infoPage.headquarters.item6?.[lang]
  },
  vatNumber: {
    title: branding.infoPage.vatNumber.title?.[lang]
  }
}
}

function createhelpPage(lang: Lang) {
	return {
  helpPageItems: createHelpPageHelpPageItems(branding.helpPage.helpPageItems, lang)
}
}

function createnetworkingArea(lang: Lang) {
	return {
  filterByTypeText: branding.networkingArea.filterByTypeText?.[lang],
  filterByPositionText: branding.networkingArea.filterByPositionText?.[lang],
  showMyContactsText: branding.networkingArea.showMyContactsText?.[lang],
  sayHelloText: branding.networkingArea.sayHelloText?.[lang],
  showMoreText: branding.networkingArea.showMoreText?.[lang],
  startVideoAudioChatText: branding.networkingArea.startVideoAudioChatText?.[lang],
  startTextChat: branding.networkingArea.startTextChat?.[lang],
  connectedText: branding.networkingArea.connectedText?.[lang],
  requestedText: branding.networkingArea.requestedText?.[lang],
  ignoredText: branding.networkingArea.ignoredText?.[lang],
  loadMoreConnectionsText: branding.networkingArea.loadMoreConnectionsText?.[lang],
  showTacticalViewText: branding.networkingArea.showTacticalViewText?.[lang],
  showSecondGenText: branding.networkingArea.showSecondGenText?.[lang],
  showRelevantConnectionsText: branding.networkingArea.showRelevantConnectionsText?.[lang],
  myConnectionsText: branding.networkingArea.myConnectionsText?.[lang],
  findNewPeopleText: branding.networkingArea.findNewPeopleText?.[lang],
  relevantTilesText: branding.networkingArea.relevantTilesText?.[lang],
  exhibitorsTitle: branding.networkingArea.exhibitorsTitle?.[lang],
  bothTitle: branding.networkingArea.bothTitle?.[lang],
  attendeesTitle: branding.networkingArea.attendeesTitle?.[lang],
  resetGraphText: branding.networkingArea.resetGraphText?.[lang],
  suggestSearchAll: branding.networkingArea.suggestSearchAll?.[lang],
  suggestNameContains: branding.networkingArea.suggestNameContains?.[lang],
  loadMoreSuggestionsText: branding.networkingArea.loadMoreSuggestionsText?.[lang],
  hideFromSuggestionsText: branding.networkingArea.hideFromSuggestionsText?.[lang],
  collapseConnections: branding.networkingArea.collapseConnections?.[lang],
  searchPlaceholderText: branding.networkingArea.searchPlaceholderText?.[lang],
  goToProfilePageText: branding.networkingArea.goToProfilePageText?.[lang],
  hideNodeText: branding.networkingArea.hideNodeText?.[lang],
  focusNodeText: branding.networkingArea.focusNodeText?.[lang],
  unfocusNodeText: branding.networkingArea.unfocusNodeText?.[lang],
  pinNodeText: branding.networkingArea.pinNodeText?.[lang],
  unpinNodeText: branding.networkingArea.unpinNodeText?.[lang],
  collapseNodeText: branding.networkingArea.collapseNodeText?.[lang],
  expandNodeText: branding.networkingArea.expandNodeText?.[lang]
}
}

function createprogramPageContent(lang: Lang) {
	return {
  programPageTitle: branding.programPageContent.programPageTitle?.[lang],
  scheduleTabTitle: branding.programPageContent.scheduleTabTitle?.[lang],
  speakersTabTitle: branding.programPageContent.speakersTabTitle?.[lang]
}
}

function createprogramSchedule(lang: Lang) {
	return {
  roundtableAccessGranted: branding.programSchedule.roundtableAccessGranted?.[lang],
  roundtableAccessRequested: branding.programSchedule.roundtableAccessRequested?.[lang],
  searchBarPlaceholder: branding.programSchedule.searchBarPlaceholder?.[lang],
  sunday: branding.programSchedule.sunday?.[lang],
  monday: branding.programSchedule.monday?.[lang],
  tuesday: branding.programSchedule.tuesday?.[lang],
  wednesday: branding.programSchedule.wednesday?.[lang],
  thursday: branding.programSchedule.thursday?.[lang],
  friday: branding.programSchedule.friday?.[lang],
  saturday: branding.programSchedule.saturday?.[lang],
  noBookmarksMessage: branding.programSchedule.noBookmarksMessage?.[lang],
  noDataMessage: branding.programSchedule.noDataMessage?.[lang],
  onlyShowBookmarksTitle: branding.programSchedule.onlyShowBookmarksTitle?.[lang],
  allStagesText: branding.programSchedule.allStagesText?.[lang],
  meetingText: branding.programSchedule.meetingText?.[lang],
  badgeAllText: branding.programSchedule.badgeAllText?.[lang],
  withBadgeText: branding.programSchedule.withBadgeText?.[lang],
  withouthBadgeText: branding.programSchedule.withouthBadgeText?.[lang],
  pointsBadgeText: branding.programSchedule.pointsBadgeText?.[lang],
  allCategoriesFilterText: branding.programSchedule.allCategoriesFilterText?.[lang],
  categoriesFilterList: createProgramScheduleCategoriesFilterList(branding.programSchedule.categoriesFilterList, lang),
  badgeFilterList: createProgramScheduleBadgeFilterList(branding.programSchedule.badgeFilterList, lang),
  noSearchResultsText: branding.programSchedule.noSearchResultsText?.[lang]
}
}

function createprogramSpeakers(lang: Lang) {
	return {
  navigationSearchPlaceholderSpeakers: branding.programSpeakers.navigationSearchPlaceholderSpeakers?.[lang],
  loadingMessage: branding.programSpeakers.loadingMessage?.[lang],
  noBookmarksMessage: branding.programSpeakers.noBookmarksMessage?.[lang],
  onlyShowBookmarksTitle: branding.programSpeakers.onlyShowBookmarksTitle?.[lang],
  noSearchResultsLabel: branding.programSpeakers.noSearchResultsLabel?.[lang],
  bookmarkedSpeakersTitle: branding.programSpeakers.bookmarkedSpeakersTitle?.[lang]
}
}

function createpressMediaPageContent(lang: Lang) {
	return {
  pressTab: {
    label: branding.pressMediaPageContent.pressTab.label?.[lang]
  },
  magazineTab: {
    label: branding.pressMediaPageContent.magazineTab.label?.[lang]
  },
  pageIntro: branding.pressMediaPageContent.pageIntro?.[lang],
  pressContent: createPressMediaPageContentPressContent(branding.pressMediaPageContent.pressContent, lang),
  largeTile: {
    title: branding.pressMediaPageContent.largeTile.title?.[lang],
    linkInfo: {
      link: branding.pressMediaPageContent.largeTile.linkInfo.link?.[lang]
    }
  },
  magazineContent: createPressMediaPageContentMagazineContent(branding.pressMediaPageContent.magazineContent, lang),
  rssFeedReadMore: branding.pressMediaPageContent.rssFeedReadMore?.[lang],
  feedEmptyMessage: branding.pressMediaPageContent.feedEmptyMessage?.[lang]
}
}

function createbusinessAreaPageContent(lang: Lang) {
	return {
  searchBarPlaceholder: branding.businessAreaPageContent.searchBarPlaceholder?.[lang],
  firstMultiSwitcherItemText: branding.businessAreaPageContent.firstMultiSwitcherItemText?.[lang],
  secondMultiSwitcherItemText: branding.businessAreaPageContent.secondMultiSwitcherItemText?.[lang],
  emptyResultText: branding.businessAreaPageContent.emptyResultText?.[lang],
  countryDropdownPlaceholer: branding.businessAreaPageContent.countryDropdownPlaceholer?.[lang],
  editMyProfil: branding.businessAreaPageContent.editMyProfil?.[lang],
  editProfileNoticeTitle: branding.businessAreaPageContent.editProfileNoticeTitle?.[lang],
  editProfileNoticeText: branding.businessAreaPageContent.editProfileNoticeText?.[lang],
  editProfileNoticeContinueToProfile: branding.businessAreaPageContent.editProfileNoticeContinueToProfile?.[lang],
  editProfileNoticeContinueWithoutProfil: branding.businessAreaPageContent.editProfileNoticeContinueWithoutProfil?.[lang],
  filterButtonText: branding.businessAreaPageContent.filterButtonText?.[lang],
  showMoreText: branding.businessAreaPageContent.showMoreText?.[lang],
  onlyShowBookmarksText: branding.businessAreaPageContent.onlyShowBookmarksText?.[lang]
}
}

function createsystemCheck(lang: Lang) {
	return {
  checkHeadline: branding.systemCheck.checkHeadline?.[lang],
  stepJavascriptName: branding.systemCheck.stepJavascriptName?.[lang],
  stepJavascriptPending: branding.systemCheck.stepJavascriptPending?.[lang],
  stepJavascriptSuccess: branding.systemCheck.stepJavascriptSuccess?.[lang],
  stepJavascriptError: branding.systemCheck.stepJavascriptError?.[lang],
  stepBrowserName: branding.systemCheck.stepBrowserName?.[lang],
  stepBrowserPending: branding.systemCheck.stepBrowserPending?.[lang],
  stepBrowserSuccess: branding.systemCheck.stepBrowserSuccess?.[lang],
  stepBrowserError: branding.systemCheck.stepBrowserError?.[lang],
  stepLocalStorageName: branding.systemCheck.stepLocalStorageName?.[lang],
  stepLocalStoragePending: branding.systemCheck.stepLocalStoragePending?.[lang],
  stepLocalStorageSuccess: branding.systemCheck.stepLocalStorageSuccess?.[lang],
  stepLocalStorageError: branding.systemCheck.stepLocalStorageError?.[lang],
  stepCameraName: branding.systemCheck.stepCameraName?.[lang],
  stepCameraPending: branding.systemCheck.stepCameraPending?.[lang],
  stepCameraSuccess: branding.systemCheck.stepCameraSuccess?.[lang],
  stepCameraError: branding.systemCheck.stepCameraError?.[lang],
  continueToMicrophone: branding.systemCheck.continueToMicrophone?.[lang],
  continue: branding.systemCheck.continue?.[lang],
  stepMicrophoneName: branding.systemCheck.stepMicrophoneName?.[lang],
  stepMicrophoneInfo: branding.systemCheck.stepMicrophoneInfo?.[lang],
  stepMicrophonePending: branding.systemCheck.stepMicrophonePending?.[lang],
  stepMicrophoneSuccess: branding.systemCheck.stepMicrophoneSuccess?.[lang],
  stepMicrophoneError: branding.systemCheck.stepMicrophoneError?.[lang],
  continueToSpeakers: branding.systemCheck.continueToSpeakers?.[lang],
  stepSpeakerName: branding.systemCheck.stepSpeakerName?.[lang],
  stepSpeakerInfo: branding.systemCheck.stepSpeakerInfo?.[lang],
  stepSpeakerPending: branding.systemCheck.stepSpeakerPending?.[lang],
  stepSpeakerSuccess: branding.systemCheck.stepSpeakerSuccess?.[lang],
  stepSpeakerError: branding.systemCheck.stepSpeakerError?.[lang],
  stepDownloadName: branding.systemCheck.stepDownloadName?.[lang],
  stepDownloadPending: branding.systemCheck.stepDownloadPending?.[lang],
  stepDownloadSuccess: branding.systemCheck.stepDownloadSuccess?.[lang],
  stepDownloadError: branding.systemCheck.stepDownloadError?.[lang],
  stepSystemName: branding.systemCheck.stepSystemName?.[lang],
  stepSystemBackToHome: branding.systemCheck.stepSystemBackToHome?.[lang],
  ticketUrl: branding.systemCheck.ticketUrl?.[lang],
  buyTicket: branding.systemCheck.buyTicket?.[lang],
  yes: branding.systemCheck.yes?.[lang],
  no: branding.systemCheck.no?.[lang],
  headline: branding.systemCheck.headline?.[lang],
  description: branding.systemCheck.description?.[lang],
  done: branding.systemCheck.done?.[lang],
  stepLocalStorageHelpTopic: branding.systemCheck.stepLocalStorageHelpTopic?.[lang],
  stepLocalStorageHelpDescription: branding.systemCheck.stepLocalStorageHelpDescription?.[lang],
  genericMissingHardwareHelpTopic: branding.systemCheck.genericMissingHardwareHelpTopic?.[lang],
  genericMissingHardwareHelpDescription: branding.systemCheck.genericMissingHardwareHelpDescription?.[lang],
  genericMacOsLevelPermissionHelpTopic: branding.systemCheck.genericMacOsLevelPermissionHelpTopic?.[lang],
  genericMacOsLevelPermissionHelpDescription: branding.systemCheck.genericMacOsLevelPermissionHelpDescription?.[lang],
  genericWindowsOsLevelPermissionHelpTopic: branding.systemCheck.genericWindowsOsLevelPermissionHelpTopic?.[lang],
  genericWindowsOsLevelPermissionHelpDescription: branding.systemCheck.genericWindowsOsLevelPermissionHelpDescription?.[lang],
  genericBrowserPermissionHelpTopic: branding.systemCheck.genericBrowserPermissionHelpTopic?.[lang],
  genericBrowserPermissionHelpDescription: branding.systemCheck.genericBrowserPermissionHelpDescription?.[lang],
  stepSpeakerHelpTopic: branding.systemCheck.stepSpeakerHelpTopic?.[lang],
  stepSpeakerHelpDescription: branding.systemCheck.stepSpeakerHelpDescription?.[lang],
  reloadHelp: branding.systemCheck.reloadHelp?.[lang],
  camera: branding.systemCheck.camera?.[lang],
  microphone: branding.systemCheck.microphone?.[lang],
  speaker: branding.systemCheck.speaker?.[lang],
  devicePermissionAsk: branding.systemCheck.devicePermissionAsk?.[lang],
  devicePermissionGranted: branding.systemCheck.devicePermissionGranted?.[lang],
  devicePermissionDenied: branding.systemCheck.devicePermissionDenied?.[lang],
  retry: branding.systemCheck.retry?.[lang],
  testSound: branding.systemCheck.testSound?.[lang],
  showResult: branding.systemCheck.showResult?.[lang],
  skippedCheck: branding.systemCheck.skippedCheck?.[lang],
  skipAndContinue: branding.systemCheck.skipAndContinue?.[lang],
  hearSound: branding.systemCheck.hearSound?.[lang],
  skippedTestResult: branding.systemCheck.skippedTestResult?.[lang]
}
}

function createnotification(lang: Lang) {
	return {
  newMessageTitle: branding.notification.newMessageTitle?.[lang],
  meetingRequestTitle: branding.notification.meetingRequestTitle?.[lang],
  meetingRequestTextTemplate: branding.notification.meetingRequestTextTemplate?.[lang],
  meetingRequestDeletedTextTemplate: branding.notification.meetingRequestDeletedTextTemplate?.[lang],
  meetingRequestParticipationDeletedTextTemplate: branding.notification.meetingRequestParticipationDeletedTextTemplate?.[lang],
  meetingRequestParticipationAcceptedTextTemplate: branding.notification.meetingRequestParticipationAcceptedTextTemplate?.[lang],
  meetingRequestParticipationDeclinedTextTemplate: branding.notification.meetingRequestParticipationDeclinedTextTemplate?.[lang],
  meetingRequestUpdatedTextTemplate: branding.notification.meetingRequestUpdatedTextTemplate?.[lang],
  connectRequestTitle: branding.notification.connectRequestTitle?.[lang],
  connectRequestAcceptedTextTemplate: branding.notification.connectRequestAcceptedTextTemplate?.[lang],
  connectRequestIncomingTextTemplate: branding.notification.connectRequestIncomingTextTemplate?.[lang],
  newBookmarkTitle: branding.notification.newBookmarkTitle?.[lang],
  newBookmarkTextTemplate: branding.notification.newBookmarkTextTemplate?.[lang],
  meetingReminderTitle: branding.notification.meetingReminderTitle?.[lang],
  meetingReminderTextTemplate: branding.notification.meetingReminderTextTemplate?.[lang],
  meetingReminderNameLink: branding.notification.meetingReminderNameLink?.[lang],
  missedCallTitle: branding.notification.missedCallTitle?.[lang],
  successCallTitle: branding.notification.successCallTitle?.[lang],
  missedCallTextTemplate: branding.notification.missedCallTextTemplate?.[lang],
  successCallTextTemplate: branding.notification.successCallTextTemplate?.[lang],
  announcementTitle: branding.notification.announcementTitle?.[lang],
  announcementText: branding.notification.announcementText?.[lang],
  virtualCafeAccessRequestTitle: branding.notification.virtualCafeAccessRequestTitle?.[lang],
  virtualCafeAccessRequestText: branding.notification.virtualCafeAccessRequestText?.[lang],
  virtualCafeAccessAcceptedTitle: branding.notification.virtualCafeAccessAcceptedTitle?.[lang],
  virtualCafeAccessAcceptedText: branding.notification.virtualCafeAccessAcceptedText?.[lang],
  virtualCafeAccessDeclinedTitle: branding.notification.virtualCafeAccessDeclinedTitle?.[lang],
  virtualCafeAccessDeclinedText: branding.notification.virtualCafeAccessDeclinedText?.[lang],
  virtualCafeAccessDeletedTitle: branding.notification.virtualCafeAccessDeletedTitle?.[lang],
  virtualCafeAccessDeletedText: branding.notification.virtualCafeAccessDeletedText?.[lang],
  virtualCafeAccessAddedTitle: branding.notification.virtualCafeAccessAddedTitle?.[lang],
  virtualCafeAccessAddedText: branding.notification.virtualCafeAccessAddedText?.[lang]
}
}

function createcoupons(lang: Lang) {
	return {
  goToDeal: branding.coupons.goToDeal?.[lang],
  explorationPhaseMessage: branding.coupons.explorationPhaseMessage?.[lang],
  postEventPhaseMessage: branding.coupons.postEventPhaseMessage?.[lang],
  dayFormat: branding.coupons.dayFormat?.[lang],
  validityTemplateStartEnd: branding.coupons.validityTemplateStartEnd?.[lang],
  validityTemplateEnd: {
    singular: branding.coupons.validityTemplateEnd.singular?.[lang],
    plural: branding.coupons.validityTemplateEnd.plural?.[lang]
  }
}
}

function createdeviceSwitcher(lang: Lang) {
	return {
  videoInputLabel: branding.deviceSwitcher.videoInputLabel?.[lang],
  noVideoInputsLabel: branding.deviceSwitcher.noVideoInputsLabel?.[lang],
  audioInputLabel: branding.deviceSwitcher.audioInputLabel?.[lang],
  noAudioInputsLabel: branding.deviceSwitcher.noAudioInputsLabel?.[lang],
  audioOutputLabel: branding.deviceSwitcher.audioOutputLabel?.[lang],
  noAudioOutputsLabel: branding.deviceSwitcher.noAudioOutputsLabel?.[lang]
}
}

function createguestUserBanner(lang: Lang) {
	return {
  bannerTextNoOrganization: branding.guestUserBanner.bannerTextNoOrganization?.[lang],
  bannerTextOrga: branding.guestUserBanner.bannerTextOrga?.[lang]
}
}

function createtopBannerConfig(lang: Lang) {
	return {
  guest: {
    bannerTextNoOrganization: branding.topBannerConfig.guest.bannerTextNoOrganization?.[lang],
    bannerTextOrga: branding.topBannerConfig.guest.bannerTextOrga?.[lang]
  },
  attendee: {
    bannerTextNoOrganization: branding.topBannerConfig.attendee.bannerTextNoOrganization?.[lang],
    bannerTextOrga: branding.topBannerConfig.attendee.bannerTextOrga?.[lang]
  },
  speaker: {
    bannerTextNoOrganization: branding.topBannerConfig.speaker.bannerTextNoOrganization?.[lang],
    bannerTextOrga: branding.topBannerConfig.speaker.bannerTextOrga?.[lang]
  },
  none: {
    bannerTextNoOrganization: branding.topBannerConfig.none.bannerTextNoOrganization?.[lang],
    bannerTextOrga: branding.topBannerConfig.none.bannerTextOrga?.[lang]
  },
  staff: {
    bannerTextNoOrganization: branding.topBannerConfig.staff.bannerTextNoOrganization?.[lang],
    bannerTextOrga: branding.topBannerConfig.staff.bannerTextOrga?.[lang]
  }
}
}

function createrulesPage(lang: Lang) {
	return {
  rulesTitle: branding.rulesPage.rulesTitle?.[lang],
  rulesSubtitle: branding.rulesPage.rulesSubtitle?.[lang],
  rules: [] as any[],
  rulesFooterItem1: branding.rulesPage.rulesFooterItem1?.[lang],
  rulesFooterItem2: branding.rulesPage.rulesFooterItem2?.[lang],
  rulesFooterItem3: branding.rulesPage.rulesFooterItem3?.[lang]
}
}

function createcsvExportBranding(lang: Lang) {
	return {
  firstName: branding.csvExportBranding.firstName?.[lang],
  lastName: branding.csvExportBranding.lastName?.[lang],
  company: branding.csvExportBranding.company?.[lang],
  jobTitle: branding.csvExportBranding.jobTitle?.[lang],
  zipCode: branding.csvExportBranding.zipCode?.[lang],
  city: branding.csvExportBranding.city?.[lang],
  country: branding.csvExportBranding.country?.[lang],
  email: branding.csvExportBranding.email?.[lang],
  phone: branding.csvExportBranding.phone?.[lang],
  interests: branding.csvExportBranding.interests?.[lang],
  optIn: branding.csvExportBranding.optIn?.[lang],
  detailPage: branding.csvExportBranding.detailPage?.[lang],
  showroom: branding.csvExportBranding.showroom?.[lang],
  requestedMeeting: branding.csvExportBranding.requestedMeeting?.[lang],
  registeredInterest: branding.csvExportBranding.registeredInterest?.[lang],
  recommendation: branding.csvExportBranding.recommendation?.[lang],
  virtualCafe: branding.csvExportBranding.virtualCafe?.[lang],
  virtualSpace: branding.csvExportBranding.virtualSpace?.[lang],
  link: branding.csvExportBranding.link?.[lang],
  media: branding.csvExportBranding.media?.[lang],
  livestream: branding.csvExportBranding.livestream?.[lang],
  conferenceSlot: branding.csvExportBranding.conferenceSlot?.[lang],
  goodieBag: branding.csvExportBranding.goodieBag?.[lang],
  lastInteraction: branding.csvExportBranding.lastInteraction?.[lang],
  markedBy: branding.csvExportBranding.markedBy?.[lang],
  markedAt: branding.csvExportBranding.markedAt?.[lang],
  press: branding.csvExportBranding.press?.[lang],
  magazine: branding.csvExportBranding.magazine?.[lang]
}
}

function createtimePickerBranding(lang: Lang) {
	return {
  hoursLabel: branding.timePickerBranding.hoursLabel?.[lang],
  minutesLabel: branding.timePickerBranding.minutesLabel?.[lang]
}
}

function createAppStringsForLang(lang: Lang) {
return {
	"configuration": createconfiguration(lang)
,"eventTiming": createeventTiming(lang)
,"press": createpress(lang)
,"globalStatePopupTexts": createglobalStatePopupTexts(lang)
,"globalTexts": createglobalTexts(lang)
,"ticketSale": createticketSale(lang)
,"loginRegistrationSite": createloginRegistrationSite(lang)
,"conferenceTexts": createconferenceTexts(lang)
,"meetingRoomGroups": createmeetingRoomGroups(lang)
,"receptionPage": createreceptionPage(lang)
,"errorSite": createerrorSite(lang)
,"communicationArea": createcommunicationArea(lang)
,"chatBranding": createchatBranding(lang)
,"calendarEntryModalPageContent": createcalendarEntryModalPageContent(lang)
,"calendarEntryListView": createcalendarEntryListView(lang)
,"calendarEntryExport": createcalendarEntryExport(lang)
,"contactEntry": createcontactEntry(lang)
,"sideIconBar": createsideIconBar(lang)
,"backendError": createbackendError(lang)
,"navigationArea": createnavigationArea(lang)
,"mediaPartnerPageContent": createmediaPartnerPageContent(lang)
,"sponsorsPageContent": createsponsorsPageContent(lang)
,"myProfilePageContent": createmyProfilePageContent(lang)
,"personDetailPageContent": createpersonDetailPageContent(lang)
,"sayHelloModal": createsayHelloModal(lang)
,"recommendModal": createrecommendModal(lang)
,"filterBar": createfilterBar(lang)
,"organizationDetailPageContent": createorganizationDetailPageContent(lang)
,"backofficeContent": createbackofficeContent(lang)
,"backofficeMeetings": createbackofficeMeetings(lang)
,"backofficeStaff": createbackofficeStaff(lang)
,"backofficeVisitors": createbackofficeVisitors(lang)
,"backofficeLounge": createbackofficeLounge(lang)
,"backofficeReporting": createbackofficeReporting(lang)
,"productDetailPageContent": createproductDetailPageContent(lang)
,"trademarkDetailPageContent": createtrademarkDetailPageContent(lang)
,"eventDateDetailPageContent": createeventDateDetailPageContent(lang)
,"productsPageContent": createproductsPageContent(lang)
,"trademarksPageContent": createtrademarksPageContent(lang)
,"newsPageContent": createnewsPageContent(lang)
,"exhibitorsPageContent": createexhibitorsPageContent(lang)
,"infoPage": createinfoPage(lang)
,"helpPage": createhelpPage(lang)
,"networkingArea": createnetworkingArea(lang)
,"programPageContent": createprogramPageContent(lang)
,"programSchedule": createprogramSchedule(lang)
,"programSpeakers": createprogramSpeakers(lang)
,"pressMediaPageContent": createpressMediaPageContent(lang)
,"businessAreaPageContent": createbusinessAreaPageContent(lang)
,"systemCheck": createsystemCheck(lang)
,"notification": createnotification(lang)
,"coupons": createcoupons(lang)
,"deviceSwitcher": createdeviceSwitcher(lang)
,"guestUserBanner": createguestUserBanner(lang)
,"topBannerConfig": createtopBannerConfig(lang)
,"rulesPage": createrulesPage(lang)
,"csvExportBranding": createcsvExportBranding(lang)
,"timePickerBranding": createtimePickerBranding(lang)

}}


export let appStrings = (branding.availableLanguages as Lang[]).map(lang => createAppStringsForLang(lang))