// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { RefObject } from 'react';

import Size from '../enums/Size';
import VideoNameplate from './VideoNameplate';
import styled from 'styled-components/macro';
import { AvatarWithDefault } from '../../ui/AvatarWithDefault';
import { useLanguageState } from '../../globalStates/LanguageState';
import branding from '../../branding/branding';
import { useActiveSpeakerContext } from '../context/ActiveSpeakerContext';


const RemoteVideoRoot = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  max-height: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #fff;
  color: #fff;
  background: ${branding.videoBackground ?? "#000"};
  
  & video {
    width: 100%;
    height: 100%;
  }

  &:hover > div {
    opacity: 1;
  }
`
const Name = styled.div`
  text-align: center;
  margin-top: 15px;
  padding: 0 15px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;

  & svg {
    margin-top: -2px;
  }
`

const Title = styled(Name)`
  font-size: 0.9em;
  margin-top: 10px;
`

const AvatarWithDefaultStyled = styled(AvatarWithDefault) <{ isSpeaking?: boolean }>`
  border: 2px solid ${props => props.isSpeaking ? "#00f" : "#000"};
  @media(max-width: 1480px){
    width: 70px;
    height: 70px;
    font-size: 22px;
    line-height: 70px;
  }
`

type Props = {
  enabled?: boolean
  videoElementRef?: RefObject<HTMLVideoElement>
  size?: Size
  attendeeId: null | string
  attendeeName?: string
  attendeeImg?: string
  attendeeCompany?: string
  attendeePosition?: string
  avatarSize?: number
  isMeetingPage?: boolean
};

export default function RemoteVideo(props: Props) {
  const strings = useLanguageState().getStrings()
  const activeSpeakerContext = useActiveSpeakerContext()

  const {
    videoElementRef,
    attendeeId,
    attendeeName,
    attendeeImg,
    attendeePosition,
    attendeeCompany,
    avatarSize,
  } = props;

  const personPosition: string = [attendeePosition, attendeeCompany]
    .filter(Boolean)
    .join(" " + strings.communicationArea.personCompanyLink + " ");
  const isSpeaking = activeSpeakerContext.isActiveSpeaker(attendeeId)
  return (
    <RemoteVideoRoot>
      {videoElementRef && <video muted ref={videoElementRef} />}
      {videoElementRef && attendeeId && <VideoNameplate attendeeId={attendeeId}/>}
      {!videoElementRef && attendeeName &&
        <>
          <AvatarWithDefaultStyled alt={attendeeName} size={avatarSize ? avatarSize : 100} src={attendeeImg} isSpeaking={isSpeaking} />
          <Name>{attendeeName}</Name>
          <Title>{personPosition}</Title>
        </>}
    </RemoteVideoRoot>
  );
}
