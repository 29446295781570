
import React, { useState, Fragment, useEffect, ReactFragment } from "react";
import { TabContentArea, TabEmpty, TabRoot, MultiSwitchRoot, NextPageLoader, NetworkingListType } from "./CommunicationArea";
import { useMeetingContext } from "../conference/context/MeetingContext";
import { MeetingParticipant, loadNotificationsByUserIdAndDisplayGroup } from "../backendServices/BackendServices";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import { useLanguageState } from "../globalStates/LanguageState";
import CrsMultiSwitch from "../ui/CrsMultiSwitch";
import styled from "styled-components";
import { ContentScrollContainer } from "../ui/ScrollContainer";
import { InviteStatus, NotificationDisplayGroup } from "../API";
import VisibilitySensor from "react-visibility-sensor"
//import { timeIntervalRead, getCurPresence} from "../globalStates/ConnectedUsers"
import branding from "../branding/branding";
import { calcLastMessageTime } from "./ConversationEntry";
import { useAppState } from "../globalStates/AppState";
import { ScheduleListType } from "./ScheduleTab";
import { doCall } from "./CommunicationOptions";
import { useChimeContext } from "../conference/context/ChimeContext";
import { isPostEventPhase } from "../EventPhaseChecker"
import { DoNotDisturbToggle } from "./components/DoNotDisturbToggle";

const NotificationsTabRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 250px);
  overflow-x: hidden;
`

interface RequestTabProps {
  userId: string
}
const RequestTab: React.FunctionComponent<RequestTabProps> = (props) => {
  const [requests, setRequests] = useState<any>([])
  const [nextPageToken, setNextPageToken] = useState<string | null>()
  const appState = useAppState()
  const languageState = useLanguageState()
  const strings = languageState.getStrings()

  const loadNextPage = async () => {

    const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: props.userId as string, displayGroup: NotificationDisplayGroup.NETWORKING, nextToken: nextPageToken! });
    if (result) {
      setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
      setRequests((oldConvos: any[]) => oldConvos.concat(result?.notificationsByUserIdAndDisplayGroupSorted?.items ?? []))
    }

  }

  useEffect(() => {
    (async () => {
      const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: props.userId, displayGroup: NotificationDisplayGroup.NETWORKING })
      if (result) {
        setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
        setRequests(result?.notificationsByUserIdAndDisplayGroupSorted?.items)
      }
    })()
  }, [props.userId])

  function clickHandler(action: string) {
    appState.setShowPeopleTab((action === "REQUEST") ? NetworkingListType.REQUESTS : NetworkingListType.CONTACTS)
  }
  if (requests && requests.length > 0) {
    return (
      <NotificationsTabRoot>
        <ContentScrollContainer>
          {requests.map((notification: any) => {
            const content = JSON.parse(notification.content)

            let messageParts = ["", ""]
            if (content.action === "REQUEST") {
              messageParts = strings.notification.connectRequestIncomingTextTemplate.split('{$senderName}')
            } else if ("CONNECT") {
              messageParts = strings.notification.connectRequestAcceptedTextTemplate.split('{$senderName}')
            }
            const message = <Fragment>{messageParts[0]} <span style={{ fontWeight: "bold" }}>{content.userName}</span> {messageParts[1]}</Fragment>

            return (
              <>
                <NotificationEntry key={notification.id} type={strings.notification.connectRequestTitle} date={calcLastMessageTime(new Date(notification.createdAt))} title={content.title} message={message} clickHandler={() => clickHandler(content.action)} />
              </>
            )
          })}
          {nextPageToken && <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { if (isVisible) loadNextPage() }}><NextPageLoader /></VisibilitySensor>}
        </ContentScrollContainer>
      </NotificationsTabRoot>
    )
  } else {
    return <TabEmpty><h4>{strings.communicationArea.notificationEmptyMessage}</h4></TabEmpty>
  }
}
interface CallTabProps {
  userId: string
}
const CallTab: React.FunctionComponent<CallTabProps> = (props) => {
  const meeting = useMeetingContext()
  const chime = useChimeContext()
  const participationHistory: MeetingParticipant[] = meeting.getParticipationHistory()
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  var callback: (param: { bookmarkChanged?: boolean, modalType?: 'none' | 'connect' | 'meeting' | 'call' | 'report' | 'guest' }) => void

  function clickHandler(personId: string) {
    doCall(personId, meeting, chime, () => callback({ modalType: "call" }))
  }
  if (participationHistory?.length > 0) {
    return <NotificationsTabRoot>
      <ContentScrollContainer>
        {participationHistory.slice(0).reverse().map((participation) => {
          if (participation.inviter) {
            let messageParts = ["", ""]
            let type = ""
            switch (participation.status) {
              case InviteStatus.ACCEPTED: messageParts = strings.notification.successCallTextTemplate.split('{$name}'); type = strings.notification.successCallTitle; break;
              case InviteStatus.CANCELED: messageParts = strings.notification.missedCallTextTemplate.split('{$name}'); type = strings.notification.missedCallTitle; break;
              case InviteStatus.DECLINED: messageParts = strings.notification.missedCallTextTemplate.split('{$name}'); type = strings.notification.missedCallTitle; break;
              case InviteStatus.INVITING: messageParts = strings.notification.missedCallTextTemplate.split('{$name}'); type = strings.notification.missedCallTitle; break;
              case InviteStatus.TIMEOUT: messageParts = strings.notification.missedCallTextTemplate.split('{$name}'); type = strings.notification.missedCallTitle; break;
            }
            if (participation.inviter.id !== props.userId) {
              const message = <Fragment>{messageParts[0]} <span style={{ fontWeight: "bold" }}>{participation.inviter.name}</span> {messageParts[1]}</Fragment>
              return (
                <NotificationEntry key={participation.id} type={type} date={calcLastMessageTime(new Date(participation.created))} message={message} clickHandler={() => clickHandler(participation.inviter.id)} />
              )
            } else
              return null
          } else
            return null
        })}
      </ContentScrollContainer>
    </NotificationsTabRoot>
  } else {
    return <TabEmpty><h4>{strings.communicationArea.notificationEmptyMessage}</h4></TabEmpty>
  }
}

interface MeetingTabProps {
  userId: string
}
const MeetingTab: React.FunctionComponent<MeetingTabProps> = (props) => {
  const [schedules, setSchedules] = useState<any>([])
  const [nextPageToken, setNextPageToken] = useState<string | null>()
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  const appState = useAppState()

  const loadNextPage = async () => {
    const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: props.userId as string, displayGroup: NotificationDisplayGroup.MEETING, nextToken: nextPageToken! });
    if (result) {
      setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
      setSchedules((oldConvos: any[]) => oldConvos.concat(result?.notificationsByUserIdAndDisplayGroupSorted?.items ?? []))
    }
  }

  useEffect(() => {
    (async () => {
      const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: props.userId, displayGroup: NotificationDisplayGroup.MEETING })
      if (result) {
        setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
        setSchedules(result?.notificationsByUserIdAndDisplayGroupSorted?.items)
      }
    })()
  }, [props.userId])

  function clickHandler(type: string) {
    appState.setShowScheduleTab(ScheduleListType.CONFIRMED)
  }

  if (schedules && schedules.length > 0) {
    return (
      <NotificationsTabRoot>
        <ContentScrollContainer>
          {schedules.map((notification: any) => {
            const content = JSON.parse(notification.content)
            let messageParts = ["", ""]
            switch (content.type) {
              case "meetingrequest": messageParts = strings.notification.meetingRequestTextTemplate.split('{$meetingTitle}').join(content.title).split('{$senderName}'); break;
              case "meetingrequestparticipationaccepted": messageParts = strings.notification.meetingRequestParticipationAcceptedTextTemplate.split('{$meetingTitle}').join(content.title).split('{$senderName}'); break;
              case "meetingrequestparticipationdeclined": messageParts = strings.notification.meetingRequestParticipationDeclinedTextTemplate.split('{$meetingTitle}').join(content.title).split('{$senderName}'); break;
              case "meetingrequestupdated": messageParts = strings.notification.meetingRequestUpdatedTextTemplate.split('{$meetingTitle}').join(content.title).split('{$senderName}'); break;
              case "meetingrequestdeleted": messageParts = strings.notification.meetingRequestDeletedTextTemplate.split('{$meetingTitle}').join(content.title).split('{$senderName}'); break;
            }
            const message = <Fragment>{messageParts[0]} <span style={{ fontWeight: "bold" }}>{content.senderName}</span> {messageParts[1]}</Fragment>
            return (
              <NotificationEntry key={notification.id} type={strings.notification.meetingRequestTitle} date={calcLastMessageTime(new Date(notification.createdAt))} title={content.title} message={message} clickHandler={() => clickHandler(content.type)} />
            )
          })}
          {nextPageToken && <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { if (isVisible) loadNextPage() }}><NextPageLoader /></VisibilitySensor>}
        </ContentScrollContainer>
      </NotificationsTabRoot>
    )
  } else {
    return <TabEmpty><h4>{strings.communicationArea.notificationEmptyMessage}</h4></TabEmpty>
  }
}


const NotificationEntryContainer = styled.div`
  font-family: ${branding.font1};
  display: flex;
  padding: 10x 20px 10px 0px;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.2);

  &.disabled {
        opacity: .6;
        pointer-events: none;
    }
`
const NotificationData = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${branding.mainInfoColor ?? "black"};

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  /* border-radius: 10px;
  border-color: #eee;
  border-style: solid; */
  padding: 15px;
`
const NotificationTypeAndDate = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #B3B3B3;
  /* color: lightgray; */
`
const NotificationTitle = styled.div`
  font-family: ${branding.font1};
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  margin-bottom: 5px;
`

interface NotificationEntryProps {
  type: string
  date: string
  title?: string
  message: ReactFragment
  clickHandler: () => void
}
const NotificationEntry: React.FunctionComponent<NotificationEntryProps> = (props) => {
  const strings = useLanguageState().getStrings()
  const disableClick: boolean = (props.type === strings.notification.successCallTitle || props.type === strings.notification.missedCallTitle) && isPostEventPhase

  return (
    <NotificationEntryContainer
      style={{ pointerEvents: disableClick ? "none" : "auto" }}
      className={disableClick ? "disabled" : ""}
      onClick={() => {
        props.clickHandler()
      }}>
      <NotificationData>
        <NotificationTypeAndDate>{props.type}: ({props.date})</NotificationTypeAndDate>
        <NotificationTitle>{props.title}</NotificationTitle>
        <div>
          {props.message}
        </div>
      </NotificationData>
    </NotificationEntryContainer>
  )
}

const StyledDoNotDisturb = styled(DoNotDisturbToggle)`
  padding: 0px 20px 40px 20px !important;
  margin-top: 20px;	
  overflow: hidden;
  border-bottom: 1px solid;
  border-color: ${branding.mainInfoColor ?? "black"};
  color: ${branding.mainInfoColor ?? "black"};

  svg {
    color: ${branding.primaryColor ?? "black"};
  }
`

export enum NotificationListType {
  REQUEST,
  CALL,
  MEETING,
  EVENT
}



const NotificationsTab: React.FunctionComponent = () => {
  const appState = useAppState()
  const userLink = useLoggedInState()
  const loggedInUserId = userLink.user()?.profileId
  const languageState = useLanguageState()
  const strings = languageState.getStrings()
  const itemList = [{ label: strings.communicationArea.notificationRequestTabTitle, value: NotificationListType.REQUEST },
  { label: strings.communicationArea.notificationCallTabTitle, value: NotificationListType.CALL },
  { label: strings.communicationArea.notificationMeetingTabTitle, value: NotificationListType.MEETING },
  { label: strings.communicationArea.notificationEventTabTitle, value: NotificationListType.EVENT }]

  const eventKey = appState.communicationCenterDisplayParam ?? NotificationListType.REQUEST

  return (
    <>
      <StyledDoNotDisturb />
      <TabRoot>
        <MultiSwitchRoot>
          <CrsMultiSwitch items={itemList} activeItem={eventKey} onItemClick={appState.setShowNotificationTab} style={{ mainWidth: '280px', msRootItem: { padding: '1px' } }} />
        </MultiSwitchRoot>
        <TabContentArea>
          {(loggedInUserId) ? MeetingTabSwitch({ eventKey: eventKey, userId: loggedInUserId }) : null}
        </TabContentArea>

      </TabRoot>
    </>
  )
}

export default NotificationsTab;



interface MeetingTabSwitchProps {
  eventKey: NotificationListType
  userId: string
}
export function MeetingTabSwitch(props: MeetingTabSwitchProps) {
  switch (parseInt(props.eventKey.toString())) {
    case NotificationListType.REQUEST: return <RequestTab userId={props.userId} />
    case NotificationListType.CALL: return <CallTab userId={props.userId} />
    case NotificationListType.MEETING: return <MeetingTab userId={props.userId} />
    case NotificationListType.EVENT: return <EventTab userId={props.userId} />
  }
  return <Fragment />
}

interface EventTabProps {
  userId: string
}
const EventTab: React.FunctionComponent<EventTabProps> = (props) => {

  const [notificationItems, setNotificationItems] = useState<any>([])
  const [nextPageToken, setNextPageToken] = useState<string | null>()
  const [firstPagePending, setFirstPagePending] = useState(true)
  const languageState = useLanguageState()
  const strings = languageState.getStrings()

  const loadNextPage = async () => {
    // HINT: Resolvers are currently using the UserPool as the sotName since SOT is not available for the CognitoUsers.
    // TODO: Remove this hint when the TODOs in the relevant Resolvers are solved.
    // Relevant resolvers:
    //  - Subscription.onNotificationCreated.req
    //  - Query.notificationsByUserIdAndDisplayGroupSorted.res
    const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: branding.configuration.sotName, displayGroup: NotificationDisplayGroup.SYSTEM, nextToken: nextPageToken! });
    if (result) {
      setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
      setNotificationItems((items: any[]) => items.concat(result?.notificationsByUserIdAndDisplayGroupSorted?.items ?? []))
    }
  }

  useEffect(() => {
    (async () => {
      // HINT: Resolvers are currently using the UserPool as the sotName since SOT is not available for the CognitoUsers.
      // TODO: Remove this hint when the TODOs in the relevant Resolvers are solved.
      // Relevant resolvers:
      //  - Subscription.onNotificationCreated.req
      //  - Query.notificationsByUserIdAndDisplayGroupSorted.res 
      const result = await loadNotificationsByUserIdAndDisplayGroup({ userId: branding.configuration.sotName, displayGroup: NotificationDisplayGroup.SYSTEM })
      if (result) {
        setNextPageToken(result?.notificationsByUserIdAndDisplayGroupSorted?.nextToken)
        setNotificationItems(result?.notificationsByUserIdAndDisplayGroupSorted?.items)
      }
      setFirstPagePending(false)
    })()
  }, [props.userId])

  function clickHandler(action: string) {

  }

  if (notificationItems.length > 0) {
    return <NotificationsTabRoot>
      <ContentScrollContainer>
        {notificationItems.map((notification: any) => {
          const content = JSON.parse(notification.content)
          const message = <Fragment><span style={{ fontWeight: "bold" }}>{content.text}</span> </Fragment>
          return <NotificationEntry key={notification.id} type={content.title} date={calcLastMessageTime(new Date(notification.createdAt))} message={message} clickHandler={() => clickHandler(content.action)} />
        })}
        {nextPageToken && <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { if (isVisible) loadNextPage() }}><NextPageLoader /></VisibilitySensor>}
      </ContentScrollContainer>
    </NotificationsTabRoot>
  } else if (firstPagePending) {
    return <NextPageLoader />
  } else {
    return <TabEmpty><h4>{strings.communicationArea.notificationEmptyMessage}</h4></TabEmpty>
  }
}
